.input_width input {
  width: 100% !important;
}
.divided_list {
  width: 100%;
  height: 100% !important;
  border: 0;
  border-radius: 0;
  padding-bottom: 0px !important;
}
.form {
  font-size: 12px !important;
  overflow: auto !important;
  height: 100% !important;
}
.tab_panel {
  width: 100% !important;
}
.tab_panel > div {
  width: 100% !important;
  display: grid !important;
  grid-template-columns: 25% 25% 25% 25% !important;
}
.tab_title {
  font-size: 12px !important;
  overflow: hidden !important;
  display: inline-block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.title {
  background-color: #6fb5eb !important;
  color: white !important;
  border: 0 !important;
  font-size: 0.97em !important;
  padding: 0.75em 1em !important;
}
.form_lines {
  font-size: 0.9em !important;
  padding-bottom: 20px !important;
}
.checkbox_resize {
  font-size: 1em !important;
}
.counter_note {
  margin: 0px !important;
  float: right;
}
.form_item_panel {
  margin-bottom: 0px !important;
}
.wordWrapClass :global .text {
  text-overflow: ellipsis !important;
  width: 100%;
  overflow: hidden !important;
}
.job_title_input {
  width: 100% !important;
}
@media (min-width: 1024px) {
  .form {
    font-size: 13px !important;
  }
  .tab_title {
    font-size: 12px !important;
  }
}
@media (min-width: 1281px) {
  .form {
    font-size: 12px !important;
  }
  .divided_list {
    padding-bottom: 10px !important;
  }
  .tab_title {
    font-size: 13px !important;
  }
}
@media (min-width: 1600px) {
  .form {
    font-size: 15px !important;
  }
  .tab_title {
    font-size: 14px !important;
  }
}
