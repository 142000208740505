.outer {
  padding-top: 0.75rem;
  max-width: 13rem;
  margin-top: -7px;
  font-size: 12px;
}

.innerInput {
  padding: 10px;
  border: 1px solid cornflowerblue;
  width: 100%;
}

.port {
  width: 24px;
  height: 24px;
  background: cornflowerblue;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nodeContentRow {
  padding-top: 0.3rem !important;
  padding-bottom: 0.5rem !important;
  background: white;
  margin-bottom: 3px;
}
.doc_number {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.preview_button {
  margin-top: 13px !important;
  background: #2185d0 !important;
  color: white !important;
  font-size: 0.9em !important;
}
.preview_button:hover {
  background: #1678c2 !important;
}
.preview_button_2 {
  margin-top: 13px !important;
  font-size: 0.9em !important;
}
.selected_lable {
  margin-top: 13px !important;
  font-size: 0.9em !important;
}
.compareModalColumn {
  width: 50% !important;
}

.compareModal {
  width: 95% !important;
}
.showDiagramButton {
  float: right;
}
