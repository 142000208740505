.list_name {
  color: #24272a;
  font-weight: 500;
  font-size: 15px;
}
.template_item {
  padding: 20px 0 !important;

  border-bottom: 1px solid #eef0f7 !important;
}
.project_list_checkbox_column {
  padding: 20px 25px 20px 8px !important;
  height: 56px !important;
}
.column_height {
  min-height: 56px;
  display: flex !important;
  justify-content: center;
  word-wrap: break-word;
}
.wrap_column {
  word-wrap: break-word;
}
