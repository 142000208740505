.bms_tablet_show {
  visibility: hidden;
}

.bms_accordion_item:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.bms_accordion {
  margin-top: 5rem !important;
}
