.disable {
  border: 0 !important;
  display: flex !important;
  justify-content: flex-end !important;
  cursor: default !important;
}
.text_align i {
  visibility: hidden !important;
}
.text_align div {
  font-size: 10px;
}
.label_style {
  color: #575d62 !important;
  font-size: 14px !important;
  display: block;
  margin: 0em 0em 0.28571429rem 0em;
  font-weight: bold;
  text-transform: none;
}
.label_required::after {
  margin: -0.2em 0em 0em 0.2em;
  content: "*";
  color: #db2828;
}

@media (min-width: 1230px) {
  .text_align div {
    font-size: inherit;
  }
}

@media (min-width: 1281px) {
  .text_align i {
    visibility: inherit !important;
  }
  .text_align div {
    font-size: inherit;
  }
}
