.bms_header {
  background: #2185d0;
  padding: 15px 10px 15px 25px;
  margin: 10px 0 10px;
  text-align: left;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}
:global .ui.positive.button {
  background-color: #3cb776;
  color: #fff;
  text-transform: uppercase;
}
:global .ui.positive.button:hover {
  background-color: #2eab69;
}
.buttons_container {
  margin-top: 10px;
}
.buttons_container :global .ui.buttons {
  width: 68%;
  max-width: 300px;
}
.buttons_container :global .ui.button {
  background: #baebd1;
  color: #3cb776;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  width: 30%;
  height: 50px;
  padding-left: 0;
  padding-right: 0;
}
.buttons_container :global .ui.button:hover {
  background: #abe6c7;
}
.buttons_container :global .ui.positive.button {
  background: #3cb776;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.buttons_container :global .ui.positive.button:hover {
  background: #23a35f;
}
.buttons_container :global .negative.ui.button {
  background: #f3d4d3;
  color: #d9534f;
  float: right;
  margin-right: 0;
}
.buttons_container :global .negative.ui.button:hover {
  background: #e6bcbb;
}
.linkForm {
  height: calc(100vh - 17.5rem);
  overflow: auto;
}
.searchBtn {
  display: grid;
  grid-template-rows: 2;
  grid-template-columns: 50% 50%;
}
.marginBottom {
  font-size: 0.85em !important;
  margin-bottom: 5px;
  margin-right: 7px !important;

  word-wrap: break-word;
  min-width: 5em !important;
}
.link_documents_search i.search.icon {
  margin-top: -1.2rem !important;
}
.input_div {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

@media (min-width: 1281px) {
  .input_div {
    display: block;
  }
}
