.container_res {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, 310px);
  grid-template-rows: repeat(auto-fit, 65px);
  height: calc(100vh - 265px);
  overflow-y: auto;
  margin-bottom: 5px;
}
.custom_system_details {
  margin-top: 10px;
  height: calc(100vh - 11em);
  overflow: auto;
  /* height: ; */
}
.test_padded {
  width: 300px;
  height: 65px;
  padding: 5px;
  border: 1px solid rgb(233, 232, 232);
  border-radius: 5px;
}
.test_grid {
  display: grid;
  grid-template-columns: 15% 78% 7%;
  height: 55px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header_grid {
  display: grid;
  grid-template-columns: 50% 50%;
}
.form_header {
  margin-top: 15px !important;
  margin-bottom: 0 !important;
}
.buttons_group {
  justify-self: end;
}
.form_button {
  font-size: 12px !important;
  min-width: 80px !important;
  margin-left: 5px !important;
}
.red_button {
  margin-right: 20px !important;
}
.text_assets {
  margin-top: 0 !important;
}
.header_divider {
  margin: 0 !important;
  width: 300px !important;
}
.pagination_position {
  display: flex !important;
  justify-content: center !important;
  background: #f8f9fd !important;
  border: none !important;
  overflow: hidden !important;
}
.pagination_position a:hover {
  cursor: pointer;
}
.pagination_position a:focus {
  outline: none !important;
}
.pagination_position :global .item {
  padding-bottom: 0.8em !important;
  text-align: center !important;
  justify-content: center !important;
}
.pagination_position :global .active.item {
  border-bottom: 3px solid black !important;
  font-weight: bold !important;
}
@media (min-width: 1281px) {
  .form_button {
    font-size: 13px !important;
    min-width: 90px !important;
  }
}
