.list_of_items :global .ui.vertical.menu span.ui.label {
  color: #fff;
  font-size: 8px;
  text-transform: uppercase;
  border-radius: 2px;
  width: calc(100% + 12px);
  text-align: center;
  margin: 2px 1px 2px 1px !important;
}
.buttons {
  width: 100%;
  height: 35px;
}
.button {
  margin-top: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
