.content_padding {
  padding: 1rem 2rem !important;
}
.rows :global .row {
  border-bottom: 1px solid #cccccc !important;
  margin: 0.1rem !important;
}
.rows :global .ui.container.stackable {
  border-bottom: 1px solid #cccccc !important;
}
