.positive {
  font-weight: bold;
  color: #3cb776;
}

.negative {
  font-weight: bold;
  color: #d9534f;
}

.positive_active {
  font-weight: bold !important;
  color: #3cb776 !important;
}

.negative_active {
  font-weight: bold;
  color: #d9534f !important;
}

.list_name {
  color: #24272a;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.8;
}

.edit_icon {
  font-size: 13px;
}

.edit_icon:hover,
.delete_icon:hover {
  color: #333;
}

.sales_assignment {
  color: #2185d0;
}

.list_desc {
  font-size: 15px;
}
.line_height {
  line-height: 1.5;
}

.paypal_icon {
  font-size: 2em !important;
}
