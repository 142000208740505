.document_payment_status_label {
  border: 1px solid #f3f5fa;
  border-image-source: linear-gradient(10deg, #f3f5fa, #b9cac4);
  border-image-slice: 1;
  text-align: center;
  padding: 3px !important;
  margin: 0 12px;
}

.document_payment_status_label :global .ui.label {
  color: #ffff;
  text-transform: uppercase !important;
  font-size: 0.6em !important;
  padding-bottom: 3px;
  padding-top: 3px;
}

.document_payment_status_label_invoice {
  border: 1px solid #f3f5fa;
  border-image-source: linear-gradient(10deg, #f3f5fa, #b9cac4);
  border-image-slice: 1;
  text-align: center;
  padding: 3px !important;
}

.document_payment_status_label_invoice :global .ui.label {
  color: #ffff;
  text-transform: uppercase !important;
  font-size: 0.6em !important;
  padding-bottom: 3px;
  padding-top: 3px;
}

.document_payment_status_label :global .ui.menu,
.document_payment_status_label_invoice :global .ui.menu {
  min-height: 0rem !important;
}

.bms_documents_item {
  padding: 10px 0px 10px !important;
}

.bms_documents_item :global .ui.grid > .column:not(.row) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.bms_documents_item,
.list_desc {
  font-size: 13px;
}

.bms_first_status span {
  font-size: 8px !important;
  width: 100%;
}

.colFixedHeight {
  height: 65px;
}
.verticalAlignColumCell {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
}

/*Start Document List/Document Item/Payment statuses legend/amount styles */
.paid {
  font-size: 9px !important;
  font-weight: 400;
  background-color: #32cd32 !important;
  width: 4.375rem;
  border-radius: 0.3125rem !important;
  opacity: 0.7;
}

.not_paid {
  background-color: #e47312 !important;
  font-size: 9px !important;
  font-weight: 400;
  width: 4.375rem;
  border-radius: 0.3125rem !important;
  opacity: 0.7;
}

.partial_paid {
  background-color: #a7c225e8 !important;
  font-size: 9px !important;
  font-weight: 400;
  width: 4.375rem;
  border-radius: 0.3125rem !important;
  opacity: 0.7;
}

.not_relevant {
  background-color: #b7b1e9 !important;
  font-size: 9px !important;
  font-weight: 400;
  width: 4.375rem;
  border-radius: 0.3125rem !important;
  opacity: 0.7;
}

.notsucc {
  background-color: #d55252 !important;
  font-size: 9px !important;
  font-weight: 400;
  width: 4.375rem;
  border-radius: 0.3125rem !important;
  opacity: 0.7;
}

.pending {
  background-color: #e5d8bf !important;
  font-size: 9px !important;
  font-weight: 400;
  width: 4.375rem;
  border-radius: 0.3125rem !important;
  opacity: 0.7;
}
/*End Document List/Document Item/Payment statuses legend/amount styles */

/*Start Document List/Document Item/Approval statuses styles */
.unresolved {
  background-color: #8d9df1 !important;
}

.approved {
  background-color: #b5d77e !important;
}

.verified {
  background-color: #77d1a1 !important;
}

.declined {
  background-color: #7ed7d3 !important;
}

.unverified {
  background-color: #8d9df1 !important;
}

.partner_name_column {
  word-wrap: break-word;
}

.doc_type {
  word-wrap: break-word;
}

/* Old styles used in global css, they re not used anymore but they are left here in case of style change requests */
/* .document_page
  .column.items_list
  .ui.vertical.menu
  span.ui.label.green.pending {
  background-color: #68b3ec !important;
}

.document_page
  .column.items_list
  .ui.vertical.menu
  span.ui.label.green.unresolved {
  background-color: #b7b1e9 !important;
} */
.savings {
  text-align: center !important;
}

.dowloadIcon {
  position: absolute;
  top: 0;
  right: -7px;
}
.item_grid {
  min-height: 4.3em !important;
}
@media (min-width: 1024px) {
  .paid,
  .not_paid,
  .partial_paid,
  .partial_paid,
  .notsucc,
  .pending,
  .not_relevant {
    width: 100% !important;
  }
  .unresolved,
  .approved,
  .declined,
  .unverified,
  .verified {
    width: 5rem !important;
  }
  .list_desc_doc_number {
    word-wrap: break-word;
  }
  .list_desc {
    font-size: 12px;
  }
}

@media (min-width: 1281px) {
  .paid,
  .not_paid,
  .partial_paid,
  .partial_paid,
  .notsucc,
  .pending,
  .not_relevant {
    width: 6rem !important;
  }
  .unresolved,
  .approved,
  .declined,
  .unverified,
  .verified {
    width: 6rem !important;
  }
  .list_desc {
    font-size: 15px;
  }
  .list_desc_doc_number {
    word-wrap: break-word;
  }
  .bms_documents_item {
    font-size: 15px;
    padding: 20px 0px 20px !important;
  }
  .bms_first_status span {
    font-size: 9px !important;
  }

  .document_payment_status_label {
    margin: 0 2rem;
  }
  .colFixedHeight {
    height: 56px;
  }
  .dowloadIcon {
    right: -9px;
  }
}
@media (min-width: 1600px) {
  .dowloadIcon {
    right: 0;
  }
}

/* .column.items_list .ui.vertical.menu .documnets_item_list span.ui.label {
  font-size: 9px !important;
  width: 95px;
} */
