.button_container {
  float: right !important;
}
.margin_bottom {
  margin-bottom: 0 !important;
}
.save_new_btn {
  color: #ffffff !important;
  margin-top: 10px !important;
}

@media (min-width: 1281px) {
  .save_btn,
  .save_new_btn {
    width: 9em;
    margin-top: 10px !important;
  }
}
