.buttons_container {
  margin-bottom: 13px !important;
}
.positive_button {
  background: #6eb4e8 !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.positive_button:hover {
  background: #61a5d7 !important;
}

.negative_button {
  background: #f3d4d3 !important;
  color: #d9534f !important;
}

.negative_button:hover {
  background: #e6bcbb !important;
}

.choose_project_dropdown :global .text {
  text-overflow: ellipsis !important;
  width: 100%;
  white-space: nowrap !important;
  overflow: hidden !important;
}
