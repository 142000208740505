.padding_left {
  padding-left: 1rem !important;
}

.bms_header {
  background: #2185d0;
  padding: 15px 10px 15px 25px;
  margin: 10px 0 10px;
  text-align: left;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  padding-right: 1rem !important;
}

.status_form_container {
  height: calc(100vh - 220px) !important;
}
