/* Documents/New Document/Link Documents/Icons */
.invoice_thumbnails {
  /* margin-top: 10px; */
  display: flex;
  flex-wrap: wrap;
  max-height: calc(100vh - 295px);
  overflow: auto;
  font-size: 10px;
  vertical-align: middle;
}
.eye_icon:hover {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .invoice_thumbnails {
    font-size: 10px;
  }
}

@media (min-width: 1281px) {
  .invoice_thumbnails {
    font-size: 13px;
  }
}
