.bms_sidebar {
  background-color: #f2f4fa !important;
  padding-top: 1rem;
  width: 260px !important;
  border: none !important;
  max-height: calc(100vh - 5rem) !important;
  z-index: 1002 !important;
}
body ::-webkit-scrollbar {
  width: 4px !important;
}
.text_center {
  text-align: center;
}

.bms_button {
  margin: 0 20px 15px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  min-height: 52px !important;
  word-spacing: 10px !important;
}
.bms_button i {
  font-family: Icons;
  /* content: "\f067"; */
  display: block;
  width: 16px;
  height: 16px;
  float: left;
  margin-right: 20px;
}
.bms_button:hover {
  background-color: #0d71bb !important;
}
.bms_sidebar :global .item {
  font-size: 15px !important;
  color: #575d62 !important;
}
.bms_sidebar :global .active.item {
  background: #fff !important;
  font-weight: 900 !important;
  -webkit-box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19);
  box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19) !important;
}
.active_menu_item {
  margin-top: 0 !important;
}
.archive_filter {
  width: 1.18em !important;
  float: left !important;
  margin: -3px 1em 0em 0em !important;
  font-size: 1.3em;
}
@media (min-width: 1281px) {
  .bms_sidebar {
    visibility: visible !important;
    width: 260px !important;
    transform: translate3d(0, 0, 0) !important;
    padding-top: 3.4rem;
  }
}
