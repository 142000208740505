body ::-webkit-scrollbar {
  width: 4px !important;
}
.details_container {
  max-height: calc(100vh - 165px) !important;
  overflow: auto;
  padding-right: 5px !important;
  font-size: 12px !important;
}
.detailsPanelHeight {
  max-height: calc(100vh - 12.5rem) !important;
  margin-top: 15px !important;
  font-size: 1em;
  padding-left: 0.2rem;
}
.details_devided {
  width: 100%;
  max-height: unset;
  overflow: hidden !important;
  border: 0 !important;
  border-bottom: 1px solid #dde1ee;
  border-radius: 0;
}
.details_devided :global .item {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
  display: flex !important;
  min-height: 3.2em !important;
}
.details_devided :global .item .content:nth-of-type(1) {
  width: 35%;
  position: relative;
}
.details_devided :global .item .content:nth-of-type(1) > strong {
  position: absolute;
  top: 50%;
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}
.details_devided :global .item .content:nth-of-type(2) {
  width: 65%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.contact_details {
  text-align: right;
  background: #f2f4fa;
  padding: 0.7rem 0.8rem;
  margin-top: 2.3rem !important;
  border-radius: 5px;
}
.note_details {
  border-radius: 5px;
  width: 100%;
}
.note_details :global div.item {
  padding: 15px 0px 2px 0px !important;
}
.header_notes {
  background-color: #f2f4fa;
  justify-content: center;
  padding: 0.75em 1em;
  border-radius: 5px;
}
.header_title {
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-weight: bold !important;
  font-size: 14px !important;
  color: #575d62 !important;
}
.header_icon {
  float: right;
}
.header_icon:hover {
  color: #2185d0;
  cursor: pointer;
}
.contact_details :global i.icons {
  font-size: 1.2em !important;
}

.contact_details :global i.icons:hover {
  color: #2185d0;
  cursor: pointer;
}
.contact_details :global i.icon.trash:hover {
  color: #d9534f;
  cursor: pointer;
}
.header_left {
  float: left !important;
  font-size: 13px;
  font-weight: 700;
}
.details_icon {
  font-size: 1.2em !important;
}
.details_align {
  text-align: right;
  padding-right: 1em;
  word-wrap: break-word;
}
.details_align_long_name {
  text-align: justify;
  padding-right: 1em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
.description_of_issue {
  word-wrap: break-word;
  padding-left: 1em;
  padding-right: 1em;
}
.section_headers {
  display: block !important;
  margin-bottom: 10px !important;
}
@media (min-width: 1281px) {
  .detailsPanelHeight {
    font-size: 14px;
  }
  .details_icon {
    font-size: 1.5em !important;
  }
  .details_container {
    font-size: 13px !important;
    max-height: calc(100vh - 195px) !important;
  }
  .contact_details :global i.icons {
    font-size: 1.5em !important;
  }

  .contact_details {
    padding: 16px 10px 16px 15px;
  }
  .header_left {
    font-size: 14px;
  }

  .details_devided :global .item .content:nth-of-type(1) {
    width: 30%;
  }
  .details_devided :global .item .content:nth-of-type(2) {
    width: 70%;
  }
}
@media (min-width: 1400px) {
  .header_left {
    font-size: 15px;
  }
  .details_container {
    font-size: 14px !important;
  }
}
@media (min-width: 1500px) {
  .header_left {
    font-size: 16px;
  }
  .details_container {
    font-size: 15px !important;
  }
}
