.wpfooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.wpfooter_margin {
  margin: 0 2vw !important;
  padding: 2vh 0;
}
