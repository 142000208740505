.new_project_table_header {
  font-size: 12px;
}
tbody tr td {
  max-width: 240px;
  word-wrap: break-word;
}
.minus_circle_icon {
  margin: 0px !important;
  border-radius: 5px !important;
}
.minus_circle_icon:hover {
  color: #d9534f !important;
  cursor: pointer !important;
}
.edit_icon:hover {
  color: rgb(148, 148, 163) !important;
  cursor: pointer !important;
}
.icons {
  width: 50px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  word-wrap: none !important;
}
