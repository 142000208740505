.account_details {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #dde1ee !important;
  margin-bottom: 20px !important;
  font-size: 12px;
}
.account_details :global .content.active {
  padding: 1.153em !important;
  padding-bottom: 0px !important;
  height: fit-content;
}
.accounts_title {
  background: #f2f4fa !important;
  color: #575d62 !important;
  font-size: 1em !important;
}
.details_devided {
  width: 100%;
  max-height: unset;
  overflow: hidden !important;
  border: 0 !important;
  border-bottom: 1px solid #dde1ee;
  border-radius: 0;
  font-size: 1em !important;
}
.details_devided :global .item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex !important;
}
.details_devided :global .item .content:first-child {
  padding-left: 0;
}
.details_devided :global .item .content:nth-of-type(1) {
  min-width: 25%;
  width: auto;
}
.details_devided :global .item .content:nth-of-type(2) {
  width: 75%;
  padding-left: 0px !important;
}
.details_align_long_name {
  text-align: justify;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.owner :global .content:nth-of-type(1) {
  width: auto;
}
@media (min-width: 1281px) {
  .account_details {
    font-size: 13px;
  }
  .details_devided :global .item .content:nth-of-type(1) {
    min-width: 30%;
    width: auto;
  }
  .details_devided :global .item .content:nth-of-type(2) {
    width: 70%;
  }
}
@media (min-width: 1024px) {
  .account_details :global .content.active {
    padding-left: 20px !important;
    padding-bottom: 0px !important;
  }
}
@media (min-width: 1600px) {
  .account_details {
    font-size: 14px;
  }
  .details_devided :global .item .content:nth-of-type(1) {
    min-width: 20%;
    width: auto;
  }
  .details_devided :global .item .content:nth-of-type(2) {
    width: 80%;
  }
}
