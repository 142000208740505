.bms_header {
  background: #2185d0;
  padding: 15px 10px 15px 25px;
  margin: -3px 0 10px;
  text-align: left;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}
/* Documents/Create New/Save+New*/
:global .ui.positive.button {
  background-color: #3cb776;
  color: #fff;
  text-transform: uppercase;
}
:global .ui.positive.button:hover {
  background-color: #2eab69;
}
/* Documents/Create New/Link Documents/Buttons OR*/
:global .ui.buttons .or {
  width: 0;
  height: auto;
  border-left: 1px solid #dde1ee;
}
/* Documents/Create New/Link Documents/Buttons OR*/
:global .ui.buttons .or:before {
  background-color: #f2f4fa;
  color: #575d62;
  text-transform: uppercase;
}
/* Create New Form inputs and dropdown align */
.bms_form_container {
  margin-top: 15px;
  height: calc(100vh - 16.5em);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0px;
}
.bms_form_container :global .ui.grid {
  margin: 0;
}
.bms_form_container :global .ui.fluid.input {
  margin-top: 7px;
}
.bms_form_container :global .content .ui.fluid.dropdown.selection {
  margin-top: 7px;
}
.bms_form_container :global .content .ui.fluid.dropdown.selection i {
  margin-top: 10x;
  padding-top: 10px;
}
.bms_form_container :global .ui.middle.aligned.divided.list {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 270px);
  border: 0 !important;
  border-radius: 0;
}
.bms_form_container :global .ui.divided.list > .item {
  border-top: 0;
  padding-bottom: 15px;
  padding-right: 10px;
}
.doc_type_input :global .dropdown.selection {
  margin-top: 0 !important;
}
.bms_form_container :global .ui.selection.dropdown .menu > .item {
  font-size: 1rem;
  padding: 1rem 1em !important;
}
.details_align_long_name {
  text-align: justify;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.refered_invoice_form_buttons {
  margin-top: 7px !important;
}
.add_partner_and_invoice_buttons :global .column {
  padding: 0 !important;
  padding-top: 19.48px !important;
}
.refered_invoice_button {
  line-height: 1.15 !important;
}
.refered_invoice_label {
  padding: 0.78571429em 0.78571429em 0.78571429em !important;
  text-overflow: ellipsis !important;
  width: 100% !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 500 !important;
}
.segment_items {
  padding: 0 !important;
  margin-right: 10px !important;
}
.segment_items :global .ui.teal.label {
  margin-left: 10px !important;
}
.segment_items > div > div > i {
  float: right !important;
  padding-right: 10px !important;
  top: 50% !important;
  -ms-transform: translateY(50%) !important;
  transform: translateY(50%) !important;
}
.segment_items :global .content {
  padding: 5px !important;
}
.required_label {
  color: #575d62 !important;
  font-size: 14px !important;
}
.rlated_inv_lable {
  margin-bottom: 7px !important;
}
.partner_info_icon {
  margin-left: 15px !important;
  font-size: 10px !important;
}
.partner_info_icon:hover {
  cursor: pointer;
}
.bms_form_container :global .ui.labeled.icon.button {
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  padding-left: 15px !important;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
}
.bms_form_container :global .ui.labeled.icon.button > .icon {
  position: relative;
  float: right;
  width: auto;
  margin-top: 12px;
  top: 6px;
}
.bms_form_container :global .ui.calendar {
  margin-top: 7px;
}
/* End Aligns dayPicker-s and other forms */

/* Start style for Order Items and Project assign tables */
.bms_form_container :global .ui.celled.table {
  border: 0;
}
.bms_form_container :global .ui.table thead th {
  background: #f2f4fa;
}
.bms_form_container :global .ui.celled.table tr th,
.bms_form_container :global .ui.celled.table tr td {
  border-left: 0;
}
.bms_form_container :global .ui.celled.table i {
  color: #8b8f9d;
  margin-right: 0;
  cursor: pointer;
  float: right;
}
.bms_form_container :global .ui.celled.table i:hover {
  color: #d9534f;
}
/* End style for Order Items and Project assign tables */

.content_height {
  min-height: 13rem;
}

.table_width {
  overflow-x: auto;
  font-size: 11px;
  min-height: 12.5rem;
}
.check_icon {
  font-size: 1.1em !important;
}
.divider_margin {
  margin-top: 2rem !important;
}
.list_padding {
  padding-left: 1rem !important;
}
.error_message_right {
  width: 100%;
  float: right;
}
.error_message_right :global div {
  float: right !important;
}
.disabled {
  pointer-events: all;
}
.input_fields_padding {
  padding: 0 !important;
}
.inputs_grid_left_padding {
  padding: 0px 10px 12px 0px !important;
}
.right_padding_0 {
  padding-right: 0 !important;
}
.input_fields_padding :global .grid.fow.column {
  padding: 0px 10px 0px 0px !important;
}
.form_row {
  padding: 4px 0px !important;
}
.input_fields_padding_right {
  padding-right: 10px;
}
.subtotal_header {
  margin-right: 10px !important;
}
.subtotal_header_2 {
  margin-bottom: 0.5em !important;
}
.validation_message_red {
  font-size: 12px;
  display: inline !important;
  color: #db2828;
  font-weight: bold;
}
.info_format {
  margin-left: 10px !important;
  margin-right: 0px !important;
}
.info_format:hover {
  cursor: pointer !important;
}
.modal_actions_padding {
  padding: 10px !important;
}
.modal_actions_padding > button {
  margin: 0 !important;
}
.partner_modal :global .content {
  padding: 10px !important;
}
.partner_modal :global .ui.vertical.menu {
  max-height: calc(100vh - 32em) !important;
  overflow-y: scroll !important;
}
.partner_modal :global .actions {
  padding: 10px !important;
}
.partner_modal :global .actions .ui.button {
  margin-top: 0px !important;
}
.remove_btn_icon:hover {
  cursor: pointer !important;
}

/* .form_group {
  display: flex !important;
  align-items: flex-end !important;
}

.form_group :global .disabled {
  opacity: inherit !important;
}

.parties_input {
  width: 100% !important;
}

.parties_input input {
  pointer-events: none !important;
}
.add_button {
  padding-right: 0 !important;
} */

.error_border {
  border: 2px solid red !important;
}

@media (min-width: 1281px) {
  .table_width {
    font-size: 12px;
    overflow-x: unset;
  }
}
