.permission_item :global a {
  font-size: 0.85em !important;
  padding: 0 !important;
}
.permission_item {
  height: 16.7em;
}
.custom_css_for_button {
  padding: 0px !important;
}
.custom_button {
  min-width: 80px !important;
  font-size: 0.8rem !important;
  padding: 0.6em 1.2em !important;
}
