.header {
  display: flex;
  margin-top: 1rem;
  font-size: 10px !important;
  margin-left: 2.8rem;
}
.project_list :global .ui.vertical.menu .item .container {
  padding: 5px 0px !important;
}
.table_header_projects {
  background: #f2f4fa;
  margin-top: 0.55rem !important;
  margin-bottom: 0.5rem !important;
  font-size: 12px;
  padding-right: 10px !important;
  border: 1px solid #f2f4fa;
  border-radius: 5px;
}
.table_header_projects h5 {
  margin-bottom: 0 !important;
  font-size: 12px;
}
.items_list {
  padding: 1rem 0 1rem 1rem !important;
}
.items_list :global .ui.vertical.menu .item.active {
  background: #f2f4fa;
  border-left: 2px solid #2185d0;
}
.items_list :global .ui.vertical.menu {
  max-height: calc(100vh - 15.5rem);
  margin-bottom: 5px !important;

  overflow: auto;
  width: 100%;
  border: 1px solid #dde1ee !important;
  border-radius: 0.28571429rem;
}
.items_list :global .ui.vertical.menu .item {
  padding-left: 0 !important;
}
.dropdown_trigger {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 1281px) {
  .table_header_projects h5 {
    font-size: 14px;
  }
  .header {
    margin-left: 0;
  }
  .project_list :global .ui.vertical.menu .item .container {
    padding: 15px 0px !important;
  }
}
