.new_item_table {
  font-size: 12px;
  height: 20px !important;
  position: static !important;
}
.new_item_table > tbody {
  height: 20px !important;
  overflow-y: scroll !important;
  margin-top: 0px !important;
}
.default_cell {
  padding: 5px 2px 5px 2px !important;
}
.dropdown_cell {
  padding: 0px 2px 0px 2px !important;
}
.new_item_table > thead > tr > th {
  padding: 5px 8px !important;
}
.document_items_header {
  padding: 1;
}
.small_input {
  width: 55px !important;
  opacity: 1 !important;
}
.middle_input {
  width: 70px !important;
  opacity: 1 !important;
}
.big_input {
  width: 90px !important;
  opacity: 1 !important;
}
.plus_button {
  background: none !important;
}
.plus_button i:hover {
  color: rgb(84, 157, 199) !important;
}
.dropdown_opened {
  text-align: left !important;
  display: inline-block !important;
  min-width: 60px !important;
}
.dropdown_opened > :nth-child(3) {
  max-height: 150px !important;
  overflow-y: scroll !important;
}
.tax_dropdown_opened {
  border: none !important;
  background: #f2f4fa !important;
  text-align: left !important;
  display: inline-block !important;
  width: 55px !important;
}
.tax_dropdown_opened > :nth-child(1),
.tax_dropdown_opened > :nth-child(2) {
  background: #f2f4fa !important;
  padding-left: 0 !important;
}
.tax_dropdown_opened > :nth-child(3) {
  background: #f2f4fa !important;
}
.validation_message_red {
  display: inline !important;
  color: #db2828;
  font-weight: bold;
}
.info_format {
  margin-left: 10px !important;
}
.info_format:hover {
  cursor: pointer !important;
}
.modal_actions_padding {
  padding: 10px !important;
}
.modal_actions_padding > button {
  margin: 0 !important;
}
.cell_width_1 {
  min-width: 58px !important;
}
.cell_width_2 {
  min-width: 70px !important;
}
.cell_width_3 {
  min-width: 105px !important;
}

@media (min-width: 1024px) {
  .minus_icon,
  .edit_icon,
  .edit_cancel,
  .edit_check {
    margin-right: 5px !important;
    color: rgba(0, 0, 0, 0.95) !important;
  }

  .edit_icon:hover,
  .edit_cancel:hover,
  .edit_check:hover,
  .minus_icon:hover {
    opacity: 0.5 !important;
    cursor: pointer !important;
  }

  .edit_check {
    font-weight: bold;
  }
}

@media (min-width: 1281px) {
  .minus_icon,
  .edit_icon,
  .edit_cancel,
  .edit_check {
    margin-right: 0 !important;
    font-size: 14px !important;
  }
}
