.invoice_full {
  margin-top: 2.3rem;
  margin-bottom: 2px;
  max-height: calc(71vh);
  overflow: auto;
  border: 1px solid #dde1ee;
}

.invoice_full_no_overflow {
  margin-top: 2.3rem;
  margin-bottom: 2px;
  max-height: calc(71vh);
  /* overflow: auto; */
  border: 1px solid #dde1ee;
  overflow: hidden;
}

.documentPreviewDetails {
  width: 100%;
  height: 70vh;
}

.documentImagePreviewContainer {
  max-width: 100% !important;
  max-height: 100% !important;
  overflow: hidden !important;
}

.clear_div {
  clear: both;
  display: block;
}

@media (min-width: 1281px) {
  .invoice_full {
    max-height: calc(81vh);
  }
  .invoice_full_no_overflow {
    max-height: calc(81vh);
  }
  .documentPreviewDetails {
    width: 100%;
    height: 80vh;
  }
}
