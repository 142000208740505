.bms_sidebar {
  background-color: #f2f4fa !important;
  padding-top: 1rem;
  width: 260px !important;
  border: none !important;
  max-height: calc(100vh - 5rem) !important;
}
.bms_sidebar :global .header {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 15px !important;
}

.bms_sidebar :global .ui.vertical.menu {
  margin-bottom: 5px;
  width: auto;
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bms_sidebar :global .item {
  font-size: 15px !important;
  color: #575d62 !important;
}

.bms_sidebar :global a.item:hover {
  margin-right: 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.03) !important;
}

.bms_sidebar :global hr {
  border: 0;
}

.bms_sidebar :global .active.item {
  background: #fff !important;
  font-weight: 900 !important;
  -webkit-box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19);
  box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19) !important;
}

.bms_sidebar :global .active.item:hover {
  background: #fff !important;
}

.bms_sidebar :global .item > i.icon {
  width: 1.18em;
  float: left !important;
  margin: -3px 1em 0em 0em !important;
  font-size: 1.3em;
}

.bms_sidebar :global .active.item > i.icon {
  color: #2185d0;
}

.bms_sidebar :global .ui.primary.button {
  margin-bottom: 10px;
  width: 90%;
  min-width: 200px;
  line-height: 30px;
  line-height: 2;
}

.bms_button {
  margin: 0 15px !important;
}

.bms_button button {
  background-color: #2185d0 !important;
  color: white !important;
}

.bms_sidebar_button {
  font-size: 14px !important;
  font-weight: 700 !important;
  min-height: 52px !important;
  word-spacing: 10px;
}

.bms_sidebar_button i {
  font-family: Icons;
  display: block;
  width: 16px;
  height: 16px;
  float: left;
  margin-right: 20px;
}
.dropdown_button {
  width: 90%;
  min-width: 200px;
  line-height: 30px !important;
  background-color: #2185d0 !important;
  color: #ffffff !important;
  text-shadow: none !important;
  background-image: none !important;
  margin: 0 15px !important;
}

.dropdown_button :global .icon.plus {
  width: 2em !important;
  background-color: #2185d0 !important;
  padding-left: 12px !important;
}

.dropdown_button :global .icon.plus.square {
  background-color: inherit !important;
  width: inherit !important;
  padding-left: 2px !important;
}

.dropdown_button :global div.text {
  word-spacing: 10px;
  padding-left: 6px !important;
}

.green_icon {
  color: #b5d77e !important;
}

.gray_icon {
  color: #7ed7d3 !important;
}

.blue_icon {
  color: #8d9df1 !important;
}

.red_icon {
  color: #e46a6a !important;
}

.pdf_icon {
  color: hsl(125deg 3% 62%) !important;
}

.paid_icon {
  color: hsl(125, 37%, 59%) !important;
}

.dropdown_style {
  background: #f2f4fa;
}

.earlypayment_dropdown {
  background: #f2f4fa;
  left: -3.7em !important;
}

.active_menu_item {
  margin-top: 0 !important;
}

.dueItem {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dueHeader {
  font-weight: bold !important;
}

@media (min-width: 1281px) {
  .bms_sidebar {
    visibility: visible !important;
    width: 260px !important;
    transform: translate3d(0, 0, 0) !important;
    padding-top: 3.6rem;
  }
}
