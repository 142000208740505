.thumb_header {
  margin: 0 0 0 0 !important;
}

.secondary_header {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #f2f4fa;
  padding: 3px;
  border: 1px solid #f2f4fa;
  border-radius: 5px;
  margin-top: 2.3rem !important;
  margin-bottom: 15px;
  height: 4.7rem;
}

.secondary_header :global .ui.button {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  height: 45px;
  width: calc(33.33% - 1rem) !important;
  margin-right: 10px;
  border-radius: 5px;
}

.last_btn {
  margin-right: 0 !important;
}

.thumb_panel_search {
  margin-top: 10px !important;
  background-color: #f2f4fa;
  padding: 10px;
  margin-bottom: 1rem;
}

.thumb_panel_search div {
  height: 25px;
}

.thumb_panel_search_input {
  margin-right: 10px;
  height: 25px;
}

.without_invoice {
  background-color: #f1c88d;
  margin-bottom: 5px !important;
}

.three_btn {
  align-items: center;
}

.invoice_thumbnails {
  margin-top: 0px;
  display: flex;
  flex-wrap: wrap;
  max-height: calc(100vh - 278px);
  overflow: auto;
}

.invoice_thumbnails :global i {
  display: block;
  position: relative;
  z-index: 0;
  float: right;
  margin: 0;
  width: 25px;
  height: 25px;
  padding: 3px;
  line-height: 1.5;
  color: #abb5bd;
  background: #f3f5fa;
}

.invoice_thumbnails :global img {
  margin-top: -25px;
  width: 100%;
}

.invoice_thumbnails :global object {
  margin-top: -25px;
  width: 100%;
}

.positive {
  font-weight: bold;
  color: #3cb776;
  margin-left: 5px;
}

.negative {
  font-weight: bold;
  color: #d9534f;
  margin-left: 5px;
}

.thumb_box {
  padding-right: 10px;
}

.linkedInvoicesThumbsLabel {
  background-color: #aacfeb;
  margin-bottom: 5px !important;
}

.searchByLabel {
  background-color: #f2f4fa;
  font-size: 0.85rem !important;
  font-weight: bold;
  margin-bottom: 5px !important;
  padding: 5px;
}

.container_style {
  width: 100%;
  margin-right: 12px;
}

.clear_div {
  clear: both;
  display: block;
}
.linked_label {
  margin-right: 1em !important;
}

.documents_for_linking_list {
  max-height: calc(100vh - 26.5rem) !important;
  font-size: 11px !important;
}

@media (min-width: 1024px) {
  .thumb_panel_search {
    font-size: 0.75rem !important;
  }
}

@media (min-width: 1281px) {
  .documents_for_linking_list {
    font-size: inherit !important;
  }

  .secondary_header {
    margin-top: 2.3rem !important;
    height: 3.8rem;
  }

  .secondary_header :global .ui.button {
    width: calc(33.33% - 7px) !important;
  }
}
