.right_panel {
  width: 100% !important;
}
.template_panel {
  padding-right: 5px !important;
}
.header {
  background: #2185d0;
  padding: 15px 25px;
  margin-top: 3.3rem;
  text-align: left;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}
.company_details_container {
  height: calc(100vh - 270px) !important;
  overflow: auto !important;
  margin-top: 25px !important;
  padding-right: 5px !important;
}
.some_form {
  margin-top: 10px !important;
}
.type_name_header {
  font-size: 16px;
}
.clear_both {
  clear: both;
  display: block;
}
.choose_type_dropdown :global .text {
  text-overflow: ellipsis !important;
  width: 100%;
  white-space: nowrap !important;
  overflow: hidden !important;
}
.choosen_type {
  word-wrap: break-word;
}
