.list_margin {
  /* margin-left: 1rem; */
  margin-top: 2.8rem;
}
.vertical_menu {
  margin-top: 0 !important;
}

.vertical_menu :global .item {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.vertical_menu :global .active {
  background: #f2f4fa !important;
  border-left: 2px solid #2185d0;
  box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19) !important;
}

.statuses_list {
  width: 100%;
  max-height: calc(100vh - 150px);
  overflow: auto;
  border: 0px solid #dde1ee !important;
  border-radius: 0.28571429rem;
}

.header_padding {
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
  background: #f2f4fa;
  margin-bottom: 0.5rem !important;
}

@media (min-width: 1281px) {
  .table_header_projects h5 {
    font-size: 14px;
  }

  .header {
    margin-left: 0;
  }

  .project_list :global .ui.vertical.menu .item .container {
    padding: 15px 0px !important;
  }

  .items_list :global .ui.vertical.menu {
    max-height: calc(100vh - 14rem);
  }
}
