.role_form_in_template {
  width: calc(100% - 5px) !important;
  margin: 3px 3px 6px 3px !important;
}
.mini_button_arrow {
  font-size: 0.3rem !important;
}
.mini_button_arrow :global i::before {
  line-height: 1;
  margin-top: 3px !important;
  vertical-align: middle !important;
}
.minus_icon {
  float: right !important;
}
.minus_icon:hover {
  color: #d9534f;
}
.roles_details_title :global span {
  display: inline-block !important;
  text-overflow: ellipsis !important;
  max-width: 80% !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}
.roles_details_title :global i,
.roles_details_title :global .dropdown.icon {
  transform: translate(-50%, 0);
}
.select_role_dd {
  width: 100% !important;
}
.select_role_dropdown :global .text {
  text-overflow: ellipsis !important;
  width: 100% !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.role_description {
  word-wrap: break-word;
}
