.listHeightOnPayMode {
  height: 33vh;
  margin-bottom: 5px !important;
}

.listOfItems :global .active.item {
  -webkit-box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19) !important;
  box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19) !important;
  background: #f2f4fa !important;
  border-left: 2px solid #2185d0 !important;
}
.listOfItems {
  margin-top: 0.5rem !important;
  width: 100% !important;
  /* max-height: calc(100vh - 13.5rem) !important; */
  overflow: auto;
  border: 1px solid #dde1ee;
  border-radius: 5px;
}
/* Payment form n Documents module */
.payListHeight {
  max-height: 28vh !important;
  margin-bottom: 5px !important ;
}
.pagination_position {
  display: flex !important;
  justify-content: center !important;
  background: #f8f9fd !important;
}

.pagination_position a:hover {
  cursor: pointer;
}

.pagination_position a:focus {
  outline: none !important;
}

@media (min-width: 1024px) {
}

@media (min-width: 1281px) {
  .listHeightOnPayMode {
    height: 40vh;
    margin-bottom: 20px !important;
  }
  .listOfItems {
    /* max-height: calc(100vh - 17rem) !important; */
  }
  .payListHeight {
    max-height: 43vh !important;
  }
}
