.bms_item {
  height: auto !important;
  padding: 8px 0px 13px !important;
  font-weight: 400;
  border-bottom: 1px #e1e6eb solid;
}
.bms_item::before {
  visibility: hidden !important;
}
.column_field {
  white-space: -webkit-pre-wrap;
  word-break: break-all;
  white-space: normal;
}
.add_or_remove_button {
  font-size: 0.8rem !important;
}
