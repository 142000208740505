.pickerMonth {
  border-color: #dededf !important;
  padding: 5px;
  margin-right: 5px;
  border-radius: 4px;
  font-family: "Lato" !important;
  display: inline-block !important;
  width: auto !important;
}

/* :global .DayPicker-Month:nth-child(2) select {
  display: none !important;
} */

/* :global .DayPicker-Month:nth-child(2) {
  margin-top: 1.2rem;
} */

.bms_day_picker {
  display: block;
  font-size: 0.7rem;
  margin-right: 5px;
}
/* 
.bms_day_picker :global .DayPicker-NavButton {
  right: 0.8em !important;
  top: 1.5em !important;
} */

.bms_day_picker span {
  right: 0.8em !important;
  top: 1.4em !important;
}

.month_header {
  display: inline-block;
  margin: 5px;
  margin-right: 10px !important;
  font-weight: 500 !important;
  font-size: 1.15em;
  width: 4em;
}

.custom_navigation {
  background-color: transparent !important;
  padding: 2px !important;
}

.custom_navigation i {
  font-size: 20px;
  color: #8b9898 !important;
}

.selectable select {
  width: inherit !important;
  margin-bottom: 10px;
  display: inline-block !important;
}
.selectable
  :global
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2 !important;
}
.selectable :global .DayPicker-Day {
  border-radius: 0 !important;
}
.selectable :global .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.selectable :global .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.form_group {
  margin: 0;
}
.span_style {
  padding: 0 10px;
}

.divider_margin {
  margin-top: 2.7rem !important;
}

@media (min-width: 1240px) {
  .bms_day_picker {
    font-size: 0.9rem;
  }
}

@media (min-width: 1281px) {
  .bms_day_picker {
  }
}
