.segment_group {
  box-shadow: none !important;
}
.title {
  display: grid;
  grid-template-columns: 92% 8%;
  background-color: #6fb5eb !important;
  color: white !important;
  font-size: 0.97em !important;
  padding: 0.75em 1em !important;
}
.company_tree :global .rst__nodeContent {
  width: 20em !important;
}
.company_tree :global .rst__rowContentsDragDisabled {
  width: 100% !important;
}
.company_tree :global .rst__rowLabel {
  width: 100% !important;
  padding: 0 !important;
}
.item_component {
  display: grid !important;
  grid-template-columns: 90% 10% !important;
}
.del_icon_grid {
  grid-template-columns: 80% 20% !important;
}
.text_item {
  width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.item_icon {
  float: right !important;
}
.delete_icon {
  float: right !important;
}
.delete_icon:hover {
  color: rgb(92, 185, 228) !important;
  cursor: pointer !important;
}
.company_tree :global .rst__nodeContent > div > .rst__rowWrapper {
  padding: 5px 0px !important;
}
.title_details {
  display: grid;
  grid-template-columns: 92% 8%;
  background-color: #f2f4fa !important;
  color: #575d62 !important;
  font-size: 0.97em !important;
  padding: 0.75em 1em !important;
}
.partners_modal {
  max-height: calc(100vh - 20em) !important;
  overflow: auto !important;
}
.formAddButton {
  padding-left: 30% !important;
  margin: auto;
  float: right;
  order: 3 !important;
}
.formAddButton :hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
}
