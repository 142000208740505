.header_above_list {
  width: 100% !important;
  background: #f2f4fa !important;
  margin: 0 !important;
  margin-bottom: 5px !important;
  min-height: 47px;
}

.header_list {
  margin: 0 !important;
}
.header_item {
  float: left;
  margin: 0 !important;
  padding: 0 !important;
}
.dropdown_trigger {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.dropdown_trigger :global h5 {
  margin: 0px !important;
}
.resizer_handler {
  background-color: rgb(216, 213, 213) !important;
  width: 2px !important;
  height: 48px !important;
  padding: 0 !important;
  float: right;
}
.resizer_handler:hover {
  background-color: rgb(128, 128, 128) !important;
  cursor: pointer;
}
.set_icon {
  position: absolute;
  top: 3px;
  right: 3px;
}
.set_icon:hover {
  cursor: pointer;
  color: rgb(54, 53, 53);
}
