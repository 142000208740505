.header_search {
  width: 145px !important;
  margin-left: 5px;
  font-size: 12px !important;
  margin-bottom: 0.55rem !important;
}

.headerActions {
  padding: 0.375rem 1.125rem !important;
}

.headerActions:hover i {
  color: rgba(0, 0, 0, 0.87) !important;
}
.headerActions:hover .green_icon {
  color: #21ba45 !important;
}

.headerActions .dropdowmMenu {
  min-width: 12em !important;
}

.check_box {
  margin-right: 1em;
  font-size: 12px !important;
}

@media (min-width: 1024px) {
  .header_search {
    width: 145px !important;
    height: 24px;
    margin-left: 5px;
  }
}

@media (min-width: 1200px) {
  .header_search {
    width: 189px !important;
  }
}

@media (min-width: 1281px) {
  .header_search {
    width: 170px !important;
    height: 24px;
    margin-left: 5px;
  }
}
