.bms_partners_item {
  border-bottom: 1px solid #dde1ee;
  padding: 15px 0px 15px !important;
  font-size: 13px !important;
}
.labels_container {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;

  height: 56px;
  line-height: 1.2em !important;
}
.grid_row_padding {
  padding: 0px !important;
}
.labels_container > div {
  max-width: 68px !important;
}
.label_row {
  color: #fff !important;
  font-size: 8px !important;
  text-transform: uppercase;
  border-radius: 2px !important;
  width: calc(100% + 0.9em) !important;
  text-align: center !important;
  margin: 1px 1px !important;
}
.orange {
  background-color: #f1c88d !important;
}
.blue {
  background-color: #aacfeb !important;
}
.green {
  background-color: #b5d77e !important;
}
.purple {
  background-color: #8d9df1 !important;
}
.cyan {
  background-color: #7ed7d3 !important;
}
.wrap_column {
  word-wrap: break-word;
}
.customer_number {
  color: #8d9df1;
  margin-bottom: 0px;
}
.supplier_number {
  color: #7ed7d3;
  margin-bottom: 0px;
}
.employee_number {
  color: #b5d77e;
  margin-bottom: 0px;
}
@media (min-width: 1281px) {
  .bms_partners_item {
    font-size: 15px !important;
    padding: 20px 0px 20px !important;
  }

  .label_row {
    width: calc(65 + 1.6em) !important;
    max-width: 75px !important;
    font-size: 9px !important;
  }

  /* .labels_container > div {
    width: 75px !important;
  } */
}
/* @media (min-width: 1400px) {
  .bms_partners_item {
    font-size: 14px !important;
  }
}
@media (min-width: 1600px) {
  .bms_partners_item {
    font-size: 15px !important;
    padding: 15px 0px 15px !important;
  }
} */
