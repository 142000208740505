.role_item a {
  font-size: 0.85em !important;
  padding: 0 !important;
}
.role_item {
  height: 16.7em;
}
.role_item_details {
  max-height: calc(50vh - 14.3rem);
}
.role_item_details a {
  font-size: 0.85em !important;
  padding: 0 !important;
}
.custom_css_for_button {
  padding: 0px !important;
}
.custom_button {
  min-width: 80px !important;
  font-size: 0.8rem !important;
  padding: 0.6em 1.2em !important;
}
/* .first_header_only div h5 {
  padding-left: 15px !important;
}
@media (min-width: 1281px) {
  .first_header_only div h5 {
    padding-left: 0px !important;
  }
} */
