.header_scan_documents {
  background: #f2f4fa;
  margin-top: 0.8rem !important;
  margin-bottom: 1rem !important;
  font-size: 14px !important;
  font-weight: bold;
  padding-right: 10px !important;
  margin-right: 5px !important;
  margin-left: 1px !important;
  border: 1px solid #f2f4fa;
  border-radius: 5px;
  width: 100%;
}

.scaned_list_toolbar_button {
  padding: 6px 18px !important;
  font-size: 12px !important;
  margin-left: 0.313rem !important;
  height: 25px;
}

.scaned_list_toolBar_btn_group {
  font-size: 12px !important;
  float: left !important;
  margin-bottom: 0.2rem !important;
  margin-top: -0.5rem !important;
  margin-left: -0.2rem !important;
}
