.colFixedHeight {
  display: flex !important;
  min-height: 52px;
  justify-content: center !important;
  padding-left: 0.7rem !important;
}

.colFixedHeight span {
  width: 122% !important;
  min-width: 68px;
  max-width: 75px !important;
  padding: 0.5em !important;
  margin-left: 2em;
}

.checkbox_column {
  width: 4% !important;
  padding: 10px !important;
}
.item_column {
  padding-left: 0.7rem !important;
  font-size: 12px;
}
.wrapColumn {
  word-wrap: break-word;
  font-size: 1em;
  margin-left: 0px !important;
}

.center_align {
  text-align: left;
  font-size: 1em;
}

.list_name {
  color: #24272a;
  font-weight: 500;
  font-size: 1em;
  line-height: 1;
}
.list_desc {
  font-size: 1em;
}

.date_wrap {
  word-wrap: break-word;
  font-size: 1em;
}

.spanLabelStyle {
  color: #fff;
  font-size: 8px;
  text-transform: uppercase;
  border-radius: 2px;
  width: calc(100% + 12px);
  text-align: center;
  margin: 1px 1px;
}

.labelUnlinked {
  background-color: #f1c88d !important;
}

.labelLinked {
  background-color: #b5d77e !important;
}

.labelWithoutDoc {
  background-color: #aacfeb !important;
}

.wrap_column {
  word-wrap: break-word;
  font-size: 1em;
}

.positive {
  font-weight: bold;
  color: #3cb776;
  margin-left: 5px;
}

.negative {
  font-weight: bold;
  color: #d9534f;
  margin-left: 5px;
}

@media (min-width: 1024px) {
  .list_item {
    font-size: 0.8rem !important;
  }
  .colFixedHeight span {
    padding: 0.5em !important;
    margin-left: 2em;
  }
}

@media (min-width: 1240px) {
  .colFixedHeight span {
    padding: 0.5833em 0.833em !important;
    margin-left: 5em;
  }

  .date_wrap {
    word-wrap: normal;
  }

  .list_item {
    font-size: 1rem !important;
  }
}

@media (min-width: 1500px) {
  .item_column {
    font-size: 13px;
  }
}
@media (min-width: 1620px) {
  .item_column {
    font-size: 14px;
  }
}
