body ::-webkit-scrollbar {
  width: 4px !important;
}
.header {
  margin-top: 2rem !important;
  padding: 0 0 0 1rem !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.header :global .item:hover {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.header_grid {
  margin: 0px 0px 0px 0px !important;
}

.download_header {
  padding-right: 1.8rem !important;
}
.middle_panel {
  max-width: calc(100% - 200px) !important;
  padding-top: 21px !important;
  margin: auto;
}
.middle_panel_list {
  max-height: calc(100vh - 185px) !important;
  height: unset !important;
  overflow: auto !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-top: 0px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.list_name {
  color: #575d62;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.8;
  word-wrap: break-word;
}

@media (min-width: 1127px) {
  .download_header {
    padding-right: 2.8rem !important;
  }
}

@media (min-width: 1239px) {
  .download_header {
    padding-right: 14px !important;
  }
}

@media (min-width: 1391px) {
  .download_header {
    padding-right: 1.9rem !important;
  }
}

@media (min-width: 1559px) {
  .download_header {
    padding-right: 0 !important;
  }
}

@media (min-width: 1281px) {
  .header {
    padding: 0 0 0 3rem !important;
  }
  .list_name {
    font-size: 15px;
  }

  .middle_panel_list {
    max-height: calc(100vh - 190px) !important;
  }
}
