.custom_disabled {
  opacity: 0.4;
}

.custom_disabled:hover {
  cursor: default !important;
}

.allButtons {
  margin-bottom: 2px !important;
  width: 7rem;
  margin-left: 2em !important;
  font-size: 10px !important;
}

@media (min-width: 1024px) {
  .allButtons {
    padding: 0.6rem 0.2rem !important;
    word-wrap: break-word;
  }
}

@media (min-width: 1240px) {
  .allButtons {
    padding: 0.6rem !important;
    width: 7rem !important;
  }
}

@media (min-width: 1281px) {
  .allButtons {
    width: 7rem !important;
    font-size: 0.8rem !important;
    padding: 0.6rem !important;
  }
}

@media (min-width: 1500px) {
  .allButtons {
    margin-left: 0 !important;
  }
}
