.grid_cont {
  font-size: 13px !important;
}
.my_divider {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.divider_actions {
  margin-bottom: 0px !important;
}
.div_actions {
  float: right !important;
  padding-bottom: 10px !important;
}
.item_in_list > a:hover {
  cursor: default !important;
  background-color: white !important;
}
