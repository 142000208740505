body ::-webkit-scrollbar {
  width: 4px !important;
}
.archive_filter {
  width: 1.18em !important;
  float: left !important;
  margin: -3px 1em 0em 0em !important;
  font-size: 1.3em;
}
.bms_sidebar {
  background-color: #f2f4fa !important;
  padding-top: 1rem;
  width: 260px !important;
  border: none !important;
  max-height: calc(100vh - 5rem) !important;
}
.bms_sidebar :global .header {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 16px !important;
}
.bms_sidebar :global .ui.vertical.menu {
  margin-bottom: 5px;
  width: auto;
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bms_sidebar :global .item {
  font-size: 15px !important;
  color: #575d62 !important;
}
.bms_sidebar :global a.item:hover {
  margin-right: 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.03) !important;
}
.bms_sidebar :global hr {
  border: 0;
}
.sync {
  float: left;
  padding-left: 1rem;
}
.bms_sidebar :global .active.item {
  background: #fff !important;
  font-weight: 900 !important;
  -webkit-box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19);
  box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19) !important;
}
.bms_sidebar :global .active.item:hover {
  background: #fff !important;
}
.bms_sidebar :global .active.item:hover {
  background: #fff !important;
}
.bms_sidebar :global .item > i.icon {
  width: 1.18em;
  float: left !important;
  margin: -3px 1em 0em 0em !important;
  font-size: 1.3em;
}
.dropdown_button {
  width: 90%;
  min-width: 200px;
  line-height: 30px !important;
  background-color: #2185d0 !important;
  color: #ffffff !important;
  text-shadow: none !important;
  background-image: none !important;
  margin: 0 15px !important;
}
.dropdown_button :global .icon.plus {
  width: 2em !important;
  background-color: #2185d0 !important;
  padding-left: 12px !important;
}
.dropdown_button :global .icon.plus.square {
  background-color: inherit !important;
  width: inherit !important;
  padding-left: 2px !important;
}
.dropdown_button :global div.text {
  word-spacing: 10px;
  padding-left: 6px !important;
}
.circle_icon {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: text-top;
}
.blue {
  border: 5px solid #aacfeb !important;
}
.orange {
  border: 5px solid #f1c88d !important;
}
.green {
  border: 5px solid #b5d77e;
}
.purple {
  border: 5px solid #8d9df1;
}
.cyan {
  border: 5px solid #7ed7d3;
}
.notif_icon {
  font-weight: 800 !important;
  color: rgb(255, 94, 0) !important;
}
.active_menu_item {
  margin-top: 0 !important;
}

@media (min-width: 1281px) {
  .bms_sidebar {
    visibility: visible !important;
    width: 260px !important;
    transform: translate3d(0, 0, 0) !important;
    padding-top: 3.4rem;
  }
}
