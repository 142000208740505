.grid_style :global .column {
  padding-bottom: 5px !important;
}
.text_three_dots {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}
.above_input,
.below_input {
  font-size: 11px;
  font-weight: 700;
}

.info_content {
  font-size: 13px;
}

.info_column {
  margin-bottom: 3em;
  margin-top: 2em;
}

@media (min-width: 1391px) {
  .item_input {
    padding-left: 2rem;
  }
}
