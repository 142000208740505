.bms_segment {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
}

.bms_segment_zi {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  z-index: 1002 !important;
}
.bms_document_content {
  margin-left: 0;
}

.bms_zi {
  z-index: 0 !important;
}

.bms_fixed {
  display: block !important;
  position: fixed !important;
  top: 5.9rem;
  left: 0px;
  width: 35px;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s width ease, 0.5s transform ease !important;
  z-index: 100 !important;
  padding: 0.7rem !important;
}

.bms_fixed i {
  margin-right: 1.3rem !important;
}

.bms_fixed span {
  font-size: 13px;
}

.bms_zindex {
  z-index: 0 !important;
}
.bms_document_content :global .ui.vertical.menu {
  max-height: calc(100vh - 17rem);
  width: 100%;
  border: 1px solid #dde1ee;
  border-radius: 0.28571429rem;
  box-shadow: none !important;
  margin-bottom: 5px !important;
}

.bms_document_content :global .ui.middle.aligned.divided.list {
  /* max-height: calc(100vh - 170px); */
  /* height: 83vh; */
  /* overflow: auto; */
  width: 100%;
  border: 1px solid #dde1ee;
  border-radius: 0.28571429rem;
}

.document_toaster :global .ui.info.message {
  bottom: 6rem !important;
}

.document_toaster :global .ui.floating.icon.warning.message {
  bottom: 6rem !important;
}
/* Start Modal why document can not be deleted */
.list_of_items {
  margin: 15px 1px 10px 1px !important;
}
.list_of_items :global .ui.vertical.menu span.ui.label {
  color: #fff;
  font-size: 8px;
  text-transform: uppercase;
  border-radius: 2px;
  width: calc(100% + 12px);
  text-align: center;
  margin: 2px 1px 2px 1px !important;
}

.status_aligment :global :nth-child(3) {
  padding-left: 25px !important;
}

.status_aligment :global span {
  color: white !important;
  margin-bottom: 3px !important;
  text-align: center;
}

.generate_pdf :global .header {
  background-color: #4a90e2 !important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}
.generate_pdf_error :global .header {
  background-color: #da8284!important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.generate_pdf_error :global .content {
  height: 7rem;
  color: #000 !important;
}

.generate_pdf :global .content {
  height: 7rem;
  color: #000 !important;
}
.generate_pdf :global .ui.loader:before {
    border-color: #00b5ad !important;
}

/* End Modal why document can not be deleted */

@media (min-width: 1281px) {
  .bms_document_content {
    margin-left: 260px;
  }

  .bms_document_no_margin {
    margin-left: 0;
  }

  .bms_fixed {
    visibility: hidden;
  }

  .bms_zi {
    z-index: 1002 !important;
  }

  .document_multiple_delete_item {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 15px;
    padding-top: 15px;
  }
}
