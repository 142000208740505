.finances-menu-item-header:hover {
  background-color: #f2f4fa !important;
}

.dms_logo {
  width: auto !important;
}

.right_menu_part {
  margin-left: 10px !important;
}
.setting_icon {
  color: #9d9d9e !important;
}

.setting_icon_active {
  color: #424242 !important;
}

.setting_icon_active:hover,
.setting_icon:hover {
  cursor: pointer !important;
}

.bms_tablet_show {
  visibility: hidden;
}

.bms_icon i {
  cursor: pointer;
}
/******** Media Query ********/
@media (min-width: 768px) {
  .bms_tablet_hide {
    display: none !important;
  }

  .bms_tablet_show {
    visibility: visible;
  }
}

@media (min-width: 1024px) {
}

/**** laptop header *****/
@media (min-width: 1200px) {
  .bms_tablet_show {
    visibility: hidden;
  }
  .bms_tablet_hide {
    display: inherit !important;
  }
}

@media (min-width: 1281px) {
  .bms_header {
    margin-right: 2.4rem !important
    ;
  }
  .dms_logo {
    margin-right: 2em !important;
  }

  .bms_bars {
    display: none !important;
  }
}
