.label_style {
  background: none !important;
  width: 100% !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}
.input_style {
  margin: 0px !important;
  width: 100% !important;
  z-index: 1 !important;
}
.input_style input {
  padding-left: 3px !important;
  padding-right: 3px !important;
}
.input_style:hover {
  cursor: pointer !important;
}
.middle_style_width {
  width: 500px !important;
}
