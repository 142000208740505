@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

/*** WELCOME PAGE abbreviation wp ***/

/* Carousel style */
/* body {
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
  padding-top: 75px;
}

* {
  outline: none;
} */

.ui.menu.header_menu {
  /* this puts the shadow above the columns below and on every other page EXEPT on this component 
  because if shadow is added it will afect on slider */
  position: relative;
  z-index: 1;

  border: 0;
  border-bottom: 1px solid #e9e9e9;
  border-radius: 0;
  -webkit-box-shadow: 0px 1px 15px #e9e9e9;
  box-shadow: 0px 1px 15px #e9e9e9;
  margin-bottom: 0;
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.slide {
  height: 70vh;
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 32px;
  height: 32px;
  cursor: pointer;
  color: #ffffff !important;
}

.previousButton {
  left: 10px;
}

.previousButton:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.nextButton {
  right: 10px;
}

polygon {
  fill: #ffffff !important;
}

.nextButton:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }

  .wpfooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
  }

  .wpfooter button {
    margin: 7px 0 !important;
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

/* slider - animations  */

.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.slide.animateOut p {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.slide.animateOut section span {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
