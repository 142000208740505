.dropdown_system {
  margin-top: 2em;
  margin-left: 2em;
}

.standardEye {
  padding-left: 1em;
}
.list_of_accounts {
  height: calc(100vh - 16em);
}
