.bms_header {
  border-top: 1px solid #dde1ee !important;
  border-bottom: 1px solid #dde1ee !important;
  border-radius: 4px !important;
  margin-top: 55px !important;
  margin-left: 1rem;
  padding: 10px 0px 15px 0px;
  font-weight: bold !important;
}
.bms_vertical :global .vertical.menu {
  width: calc(100% - 60px) !important;
  margin-right: 10px;
  margin-left: 30px;
}

.bms_vertical a {
  padding: 20px 0 25px 0 !important;
}

.bms_bold_header {
  font-weight: bold;
}

.positive {
  font-weight: bold;
  color: #3cb776;
}

.negative {
  font-weight: bold;
  color: #d9534f;
}

.sales_assignment {
  color: #2185d0;
}

.list_name {
  color: #24272a;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.8;
}

.list_desc {
  font-size: 15px;
}

.empty_list_message {
  bottom: 5px !important;
  width: calc(100% - 60px) !important;
  margin-right: 10px !important;
  margin-left: 30px !important;
}

@media (min-width: 1281px) {
  .bms_vertical :global .vertical.menu {
    width: calc(100% - 60px) !important;
  }
}
