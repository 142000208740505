h4 {
  margin-bottom: 5px;
}

.disable_input {
  opacity: 0.9 !important;
}

.disable_input div {
  opacity: 0.9 !important;
}

.disable_input label,
.label_input {
  opacity: 0.96 !important;
  font-size: 0.92em !important;
}

.between_info {
  align-self: flex-end !important;
}

.form_container {
  height: calc(100vh - 15.6em) !important;
  overflow-x: auto;
  padding: 10px;
}

.form_container_details {
  height: calc(100vh - 10em) !important;
  overflow-x: auto;
  padding: 10px;
}
.partner_grid {
  width: 100%;
}

.four_input_form {
  padding-bottom: 1em;
}
