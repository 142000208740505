.listScroll {
  margin: 0px !important;
  overflow: auto;
  height: 15em !important;
}
.segment_list {
  height: 19em !important;
  position: relative !important;
  font-size: 11px;
}
.padding_l_r_zero {
  padding: 8px 0px !important;
}
.border_style {
  border-top: none !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
}
.button_column_width {
  display: inline-block !important;
}
.add_remove_button {
  font-size: 11px !important;
  padding: 0.58571429em 1.5em !important;
  width: 7em !important;
  display: inline-block;
}
.document_number_column {
  word-wrap: break-word !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.anchor_icon {
  margin-right: 8px !important;
}
.icon_size {
  font-size: 1.7rem !important;
}
.headerBackground {
  width: 100% !important;
  background: #f2f4fa;
  margin: 5px 0px !important;
  font-size: 12px !important;
}
.wrap_column {
  word-wrap: break-word;
  padding: 10px !important;
}
.message_style {
  margin-top: 10px !important;
}

@media (min-width: 1350px) {
  .segment_list {
    font-size: 13px !important;
  }
  .headerBackground {
    background: #f2f4fa;
    font-size: 13px;
  }
}
