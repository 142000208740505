.bms_dashboard {
  height: calc(100vh - 80px);
  overflow: auto;
}

.bms_statistic_info {
  justify-content: space-around !important;
}

.dashboard_header {
  background-color: #e6d8d8cc !important;
}

.documents_type {
  background-color: #f5f5f5;
  height: 250px;
}

.documents_type_details {
  background-color: #f5f5f5 !important;
}

.documents_transactions_linked {
  background-color: #f5f5f5;
}

.partners_details {
  background-color: #f5f5f5;
  height: 250px;
}
