.details_header {
  margin-top: 2.2rem;
  margin-bottom: 1.5rem !important;
}

.header_above_list {
  width: 100%;
  background: #f2f4fa;
  height: 44px;
  padding: 10px !important;
  padding-left: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  font-weight: bold;
}
