.accordition_container {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #dde1ee;
  width: 100% !important;
  margin: 0px !important;
  margin-bottom: 10px !important;
  font-size: 12px !important;
}
.accordition_container :global .title,
.accordition_container :global .active.title {
  background: #f2f4fa !important;
  color: #575d62 !important;
  font-size: 0.97em !important;
  display: grid;
  grid-template-columns: 5% 90% 5%;
  word-break: break-word;
  padding: 0.70em 1em !important
}
.dropdown_icon {
  margin: auto !important;
}
.ac_content {
  padding: 15px !important;
}
.ac_content > div {
  font-size: 0.9em;
}
.minus_circle_icon {
  color: #8f94a3 !important;
  font-size: 15px !important;
  margin: auto !important;
  padding-left: 30%;
  order: 3 !important;
}
.minus_circle_icon:hover {
  color: #d9534f !important;
}
.address_col :global .field {
  width: 50% !important;
}
@media (min-width: 1024px) {
  .accordition_container {
    font-size: 12px !important;
  }
}
@media (min-width: 1281px) {
  .accordition_container {
    font-size: 13px !important;
  }
}
@media (min-width: 1600px) {
  .accordition_container {
    font-size: 14px !important;
  }
}
