.header_bms {
  margin-top: 2.2rem !important;
}

.bms_header {
  background: #2185d0;
  padding: 15px 10px 15px 25px;
  margin-top: 2.4rem !important;
  margin-bottom: 0.8rem !important;
  text-align: left;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}

.panel_area {
  height: 75% !important;
  text-align: center;
  font-size: 36;
  background-color: rgba(255, 255, 255, 0.8);
  border: dashed #f2f4fa 4px;
}

.panel_area :global .button {
  margin-top: 2%;
}

.panel_area :global div:first-child {
  font-size: 22px;
  color: #c7c9ce;
  margin-top: 40%;
}

@media (min-width: 1024px) {
  .panel_area {
    height: 60% !important;
  }
  .panel_area :global div:first-child {
    font-size: 22px;
    color: #c7c9ce;
    margin-top: 30%;
  }
}

@media (min-width: 1281px) {
  .panel_area {
    height: 75% !important;
  }
  .panel_area :global div:first-child {
    margin-top: 40%;
  }
}
