.bms_projects_content {
  max-height: calc(100vh - 4rem);
}
.bms_projects_content :global .grid {
  margin-left: 0rem;
  margin-right: 0rem;
}
.bms_projects_content object {
  width: 100%;
  height: 79vh;
  margin-top: 3.2rem;
}
.bms_segment {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  height: 100vh !important;
}
.bms_segment_zi {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  z-index: 1000 !important;
}

.bms_fixed {
  display: block !important;
  position: fixed !important;
  top: 5.7rem;
  left: 0px;
  width: 35px;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s width ease, 0.5s transform ease !important;
  z-index: 100 !important;
  padding: 0.7rem !important;
}
.bms_zindex {
  z-index: 0 !important;
}
.bms_projects_content :global #showScan {
  margin-top: 3.2rem;
}
.bms_projects_content :global #showScan object {
  margin-top: 0 !important;
}
.bms_projects_content :global #showScan .item {
  height: 84vh !important;
  overflow: inherit !important;
}
.project_toaster :global .ui.info.message {
  bottom: 6rem !important;
}
.project_toaster :global .ui.floating.icon.warning.message {
  bottom: 6rem !important;
}
.project_multiple_delete_item {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 5px;
  padding-top: 5px;
}

@media (min-width: 1281px) {
  .bms_projects_content {
    margin-left: 260px;
  }
  .bms_fixed {
    visibility: hidden;
  }
  .bms_projects_content object {
    width: 100%;
    height: 87vh;
    margin-top: 3.2rem;
  }
}
