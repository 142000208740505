.addingPartnerNewDocument {
  /* width: 68rem !important; */
  height: 46.8rem !important;
  margin-left: 2.1rem;
  border: 1px solid rgb(221, 219, 219);
  overflow: auto !important;
}
.addingPartnerEditDocument {
  /* width: 68rem !important; */
  height: 46.8rem !important;
  border: 1px solid rgb(221, 219, 219);
  margin-left: 2.1rem;
  overflow: auto !important;
}

.addingPartnerNewDocument object {
  height: 112vh !important;
}

.addingPartnerEditDocument object {
  height: 112vh !important;
}

.new_document_display {
  margin-top: 35px;
  height: 90vh !important;
  max-height: calc(100vh - 125px) !important;
}

.documentEditPreviewDetails {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.documentEditPreviewContainer {
  width: 100%;
  height: 87vh;
  overflow: auto;
  border: none;
  margin-top: 10px;
}

.documentEditPreviewContainer object {
  height: 82vh;
}

.documentPreview {
  width: 100%;
  height: 77vh;
}

@media (min-width: 1281px) {
  .addingPartnerNewDocument object {
    height: 69vh !important;
  }

  .addingPartnerEditDocument object {
    height: 69vh !important;
  }

  .documentEditPreviewContainer object {
    height: 86vh;
  }
  .documentPreview {
    height: 86vh;
  }
}
