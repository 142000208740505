.menu_item {
  font-weight: 400 !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
  font-size: 13px;
}
.menu_item:hover {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.download_icon:hover {
  cursor: pointer !important;
}
.my_grid {
  margin: 0 !important;
  padding: 0 0 0 1rem !important;
  word-wrap: break-word !important;
}

@media (min-width: 1281px) {
  .menu_item {
    font-size: 15px;
  }
  .my_grid {
    padding: 0 0 0 3rem !important;
  }
}
