.bms_segment {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
}

.bms_segment_zi {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  z-index: 1002 !important;
}
.bms_partners_content {
  margin-left: 0;
  width: 100%;
  height: 100vh;
}
.grid_middle_panel {
  display: grid !important;
  grid-template-columns: 60% 40% !important;
}
.bms_zi {
  z-index: 0 !important;
}
.bms_fixed {
  display: block !important;
  position: fixed !important;
  top: 5.9rem;
  left: 0px;
  width: 35px;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s width ease, 0.5s transform ease !important;
  z-index: 100 !important;
  padding: 0.7rem !important;
}
.bms_fixed i {
  margin-right: 1.3rem !important;
}
.bms_fixed span {
  font-size: 13px;
}
.bms_zindex {
  z-index: 0 !important;
}
.full_width {
  width: 100% !important;
  height: calc(100vh - 75px) !important;
}
.three_column_grid {
  height: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.modals_header {
  background-color: #c00 !important;
  color: white !important;
}
@media (min-width: 1281px) {
  .bms_partners_content {
    margin-left: 260px;
    width: calc(100% - 260px);
  }

  .bms_fixed {
    visibility: hidden;
  }

  .bms_zi {
    z-index: 1002 !important;
  }
}
