.segment_group {
  box-shadow: none !important;
}
.title {
  display: grid;
  grid-template-columns: 92% 8%;
  background-color: #f2f4fa !important;
  color: #575d62 !important;
  font-size: 0.97em !important;
  padding: 0.75em 1em !important;
}
.accordition_container {
  margin-bottom: 5px !important;
}
