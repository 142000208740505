.header_bms {
  margin-top: 2.2rem !important;
}

.table_header_documents {
  background: #f2f4fa;
  height: 3.9rem;
  margin-top: 3.55rem !important;
  margin-bottom: 0rem !important;
  font-size: 12px;
  padding-right: 10px !important;
  border: 1px solid #f2f4fa;
  border-radius: 5px;
  width: auto !important;
}

.bms_header_small_screen {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  height: 3.9rem;
}

.table_header_documents h5 {
  margin-bottom: 0em;
}

.table_header_documents :global .dropdown {
  padding-left: 10em;
  padding-right: 0em;
}

.lastColumn {
  text-align: center;
}

.paddingRight {
  padding-right: 0.2rem !important;
}
.trigger_style {
  display: flex;
}

.trigger_style h5 {
  font-size: 12px;
}

.document_header_search {
  display: flex;
  font-size: 10px !important;
}

.bms_check_all {
  padding-right: 5px !important;
}

.documents_pay_btn {
  font-size: 0.9rem !important;
  padding: 6px 20px !important;
  margin-left: 1rem !important;
  height: 24px !important;
}

.bms_before_header {
  margin-left: 2.8rem;
  position: fixed;
  z-index: 1;
}

.bms_before_header_edit {
  margin-left: 2.8rem;
  position: fixed;
}

.bms_before_header :global .ui.button {
  /* font-size: 0.9rem; */
  padding: 0.375rem 1.125rem;
  /* margin-left: 0.313rem; */
}

.bms_before_header :global .checkbox {
  font-size: 0.9rem;
}

#closeBtn {
  height: 1.55rem;
  margin-top: 0.063rem;
  width: 2.875rem;
  margin-right: 0.625rem;
  background-color: #fff;
}

.headerMarginOnPay {
  margin-top: 1px !important;
  margin-left: 1px !important;
  padding: 0 2rem 0 1rem !important;
}

.dropdown_item_status {
  padding-left: 5px;
}

.selectableDayPicker {
  font-size: 11px !important;
}

.selectableDayPicker
  :global
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.selectableDayPicker :global .DayPicker-Day {
  border-radius: 0 !important;
}

.selectableDayPicker :global .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.selectableDayPicker :global .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.right_padding {
  padding-right: 2px !important;
}

/*Start Document Date selection */
.date_type_and_picker {
  font-size: 12px !important;
  margin-left: 0.5rem !important;
}

.date_type_and_picker :global .ui.selection.dropdown {
  min-height: 24px;
  width: 13rem;
}

.date_type {
  padding: 5px !important;
  margin-left: 0.313rem;
}

.date_type :global .icon {
  padding-left: 5px !important;
  padding-top: 5px !important;
  margin-left: 4rem;
}

.date_range {
  padding: 5px !important;
  margin-left: 0.313rem;
  font-size: 11px !important;
}

.date_range :global .input {
  padding-left: 5px !important;
  height: 24px !important;
  min-height: 24px !important;
  width: 14rem;
}

.bottom_margin {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

/*End Document Date selection */

.dropdown_sort {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding-left: 0px !important;
}

.quick_modal :global .header {
  background-color: #4a90e2 !important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.quick_modal :global .content {
  min-height: 24rem;
}

.set_icon {
  position: absolute;
  top: 3px;
  right: -8px;
}

.set_icon:hover {
  cursor: pointer;
  color: rgb(54, 53, 53);
}

.break_word {
  word-break: break-all;
  min-width: 5rem;
}
@media (min-width: 1024px) {
  .selectableDayPicker {
    font-size: 12px !important;
  }
  .dayPickers :global .ui.attached.buttons {
    width: 16rem !important;
  }
  .selectableDayPicker :global .DayPicker-Month {
    margin-left: 1.2rem !important;
  }
  .date_type {
    min-width: 110px !important;
  }
  .date_range {
    min-width: 110px !important;
  }
  .date_type_and_picker :global .ui.selection.dropdown {
    width: 11rem;
  }
  .date_range :global .input {
    width: 13rem;
  }
}

@media (min-width: 1200px) {
  .date_type_and_picker :global .ui.selection.dropdown {
    width: 13rem;
  }
}

@media (min-width: 1281px) {
  .bms_header_small_screen {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    flex-wrap: nowrap !important;
  }
  .documents_pay_btn {
    padding: 6px 25px !important;
    height: 24px !important;
  }
  .bms_check_all {
    padding-right: 20px;
  }

  .bms_before_header {
    margin-left: 0;
  }
  .selectableDayPicker {
    font-size: 12px !important;
  }
  .selectableDayPicker :global .DayPicker-Month {
    margin-left: 1.2rem !important;
  }
  .date_type {
    height: 24px !important;
    min-width: 180px !important;
  }
  .date_range {
    min-width: 180px !important;
  }
  .date_type_and_picker :global .ui.selection.dropdown {
    width: 12rem;
  }

  .date_range :global .input {
    width: 12rem;
  }
  .trigger_style h5 {
    font-size: 14px;
  }
  .break_word {
    min-width: max-content;
  }
}
