.input_style {
  padding: 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  background: #ffffff;
  text-overflow: ellipsis;
}
.active.input_style {
  border: 2px solid red;
}
.input_style > div {
  margin: 0em;
  margin-top: 0.2em;
  min-height: 20px;
  margin-bottom: 0.2em;
  width: 80%;
  flex: 1 0 auto;
  outline: none;
  text-align: left;
  line-height: 1.21428571em;
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.47857143em 0.4em;
  background: #ffffff;
}
.input_style > i {
  width: 20% !important;
  margin: 0em !important;
  margin-top: 5px !important;
  height: 1.21428571em !important;
  padding: 0px !important;
  background: #ffffff;
  top: 50% !important;
  -ms-transform: translateY(50%) !important;
  transform: translateY(50%) !important;
}
.input_style:hover {
  cursor: pointer !important;
}
.label_style {
  background: none !important;
  width: 100% !important;
  min-width: 55px !important;
}
