.edit_projects_zindex1 {
  z-index: 1000;
  height: calc(100vh - 4rem);
}
.edit_projects_zindex2 {
  z-index: 1;
}
.project_edit_header {
  margin: 3.7rem 0 0 0;
  background: #2185d0;
  padding: 15px 25px;
  text-align: left;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  border: 1px solid #2185d0;
  margin-top: 2.2rem !important;
}
.company_details_container {
  height: calc(100vh - 270px);
  overflow: auto;
  margin-top: 25px;
  margin-bottom: 2rem;
  padding-right: 5px;
}
.project_template :global .text {
  text-overflow: ellipsis !important;
  width: 100%;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.project_template_margin {
  margin-bottom: 20px;
}
.item_content_top_bottom {
  padding-bottom: 10px;
  padding-top: 10px;
}
.segment_titles {
  font-size: 16px;
}
.segment_group {
  margin-bottom: 20px;
}
.partner_counter {
  display: block;
  float: right;
}
.partner_counter_label {
  margin: 0 15px 0 6px !important;
}
.dialog_error {
  clear: both;
  display: block;
}
