.details_container {
  height: 38.5% !important;
  overflow: auto;
  padding-right: 5px;
}
.details_container :global .ui.icon.message {
  width: calc(100% + 5px);
}
.details_container :global form {
  height: calc(100vh - 23em) !important;
  overflow: auto !important;
}
.header_create {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2185d0 !important;
  margin-top: 2.5rem !important;
  text-align: left;
  line-height: 20px;
  color: #fff;
  padding: 4px 10px !important;
  border: 1px solid #2185d0;
  margin-bottom: 10px;
  font-size: 13px;
}
.header_edit {
  line-height: 26px;
  padding: 5px 10px !important;
}
.header_name {
  font-weight: bolder;
  font-size: 1.1333em !important;
}
.partner_type_dropdown {
  padding: 0.7em !important;
  font-size: 0.9em !important;
  min-height: 0.5em !important;
}
.partner_type_dropdown > i {
  margin: 0px !important;
  padding: 0px !important;
}
.contacts_buttons {
  margin-top: 15px;
  margin-bottom: 0px;
}
.buttons_container_shadow {
  position: relative;
  z-index: 1;
  margin-top: -30px;
  margin-bottom: 10px;
  box-shadow: 0 -15px 18px -20px rgba(0, 0, 0, 0.7) inset;
  border-bottom: 1px solid #e9e9e9;
  height: 30px;
  margin-left: -1px;
  margin-right: -1px;
}
.buttons {
  width: 100%;
  display: grid;
  grid-template-columns: 68% 32%;
}
.buttons_left {
  width: 70%;
  display: flex;

  border-radius: 5px !important;
}
.button {
  margin-right: 0 !important;
  border: 0px;
  width: 100%;
  min-width: 120px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  height: 50px !important;
}
.positive {
  max-width: 300px;
  background: #3cb776;
  color: #fff;
  border-radius: 5px;
}
.create_positive {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.positive:hover {
  background: #23a35f;
}
.positive_new {
  max-width: 300px;
  background: #baebd1;
  color: #3cb776;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.positive_new:hover {
  background: #abe6c7;
}
.negative {
  max-width: 150px;
  background: #f3d4d3 !important;
  color: #d9534f !important;
  float: right !important;
  border-radius: 5px;
}
.negative:hover {
  background: #e6bcbb !important;
}
.or_divider {
  height: 50px !important;
  width: 5px !important;
  color: #b3b3b3 !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
}
.or_divider:before {
  width: 25px !important;
  height: 25px !important;
  margin-top: -12px !important;
  line-height: 2 !important;
}
@media (min-width: 1281px) {
  .header_edit {
    line-height: 39px !important;
  }
  .header_create {
    line-height: 38px !important;
    padding: 6px 10px !important;
    font-size: 15px !important;
  }
  .partner_type_dropdown {
    padding: 0.769em !important;
    font-size: 0.95em !important;
    line-height: 1em !important;
    min-height: 2.71428571em !important;
  }
  .contacts_buttons {
    margin-top: 0px;
  }
  .details_container {
    height: calc(100vh - 255px);
    padding-right: 5px;
  }
}

@media (min-width: 1253px) {
  .details_container :global form {
    height: calc(100vh - 22.5em) !important;
  }
}
@media (min-width: 1281px) {
  .details_container :global form {
    height: calc(100vh - 27em) !important;
  }
}
@media (min-width: 1600px) {
  .details_container :global form {
    height: calc(100vh - 22em) !important;
  }
}
@media (min-width: 1718px) {
  .details_container :global form {
    height: calc(100vh - 21em) !important;
  }
}
