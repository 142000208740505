.export_sidebar {
  height: 100vh !important;
  background: #f2f4fa !important;
  width: 285px !important;
  padding-top: 4.2rem !important;
  margin-right: 13px !important;
}
.settings_button {
  width: 1.18em !important;
  float: left !important;
  margin: -3px 1em 0em 0em !important;
  font-size: 1.3em !important;
  color: #8b8f9d !important;
}
.vertical_menu {
  width: auto !important;
  background: transparent !important;
  border: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.settings_item {
  padding: 15px 0px 15px 25px !important;
}
.new_transfer_button {
  font-size: 14px !important;
  font-weight: 700 !important;
  min-height: 52px !important;
  margin-bottom: 20px !important;
  width: 235px !important;
  word-spacing: 4px !important;
}
.button_center {
  text-align: center;
}
.bms_sidebar {
  background-color: #f2f4fa !important;
  padding-top: 1rem;
  width: 260px !important;
  border: none !important;
  max-height: calc(100vh - 5rem) !important;
}
.bms_sidebar :global .header {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 16px !important;
}

.bms_sidebar :global .ui.vertical.menu {
  margin-bottom: 5px;
  width: auto;
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bms_sidebar :global .item {
  font-size: 15px !important;
  /* padding: 10px 25px !important; */
  color: #575d62 !important;
}

.bms_sidebar :global a.item:hover {
  margin-right: 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.03) !important;
  /* color: rgba(0, 0, 0, 0.95); */
  /* padding: 10px 40px; */
}

.bms_sidebar :global hr {
  border: 0;
  /* border-top: 1px solid #dde1ee; */
}

.bms_sidebar :global .active.item {
  background: #fff !important;
  /* margin-left: -15px;
    margin-right: 0;
    padding: 10px 40px; */
  font-weight: 900 !important;
  -webkit-box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19);
  box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19) !important;
}

.bms_sidebar :global .active.item:hover {
  background: #fff !important;
}

.bms_button {
  margin: 0 15px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  min-height: 52px !important;
  word-spacing: 10px !important;
}

.bms_sidebar_button {
  font-size: 14px;
  font-weight: 700;
  min-height: 52px;
  word-spacing: 10px;
}

.bms_sidebar_button i {
  font-family: Icons;
  /* content: "\f067"; */
  display: block;
  width: 16px;
  height: 16px;
  float: left;
  margin-right: 20px;
}

.bms_sideBar_h4 {
  font-size: 17px;
  padding-left: 25px;
}
.bms_button .icon_margin {
  margin: 0em 0.42857143em 0em -0.21428571em !important;
}

@media (min-width: 1281px) {
  .bms_sidebar {
    visibility: visible !important;
    width: 260px !important;
    transform: translate3d(0, 0, 0) !important;
    padding-top: 3.6rem;
  }
}
