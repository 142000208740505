.toolBarForm {
  font-size: 12px !important;
}

.checkboxAll {
  margin: 0px 5px;
  font-size: 12px !important;
}

.toolbar_button {
  padding: 6px 18px !important;
  font-size: 12px !important;
  margin-left: 0.313rem !important;
}

.toolBarForm :global .ui.button.disabled {
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset !important;
}

.header_search {
  width: 145px !important;
  margin-left: 5px;
  font-size: 0.9rem;
}

.date_type {
  padding: 5px !important;
  margin-left: 0.313rem;
  height: 24px !important;
}

.date_range {
  padding: 5px !important;
  margin-left: 0.313rem;
  font-size: 11px !important;
}

.date_range :global .input {
  padding-left: 5px !important;
  height: 24px !important;
  min-height: 24px !important;
  width: 14rem;
}

.selectable
  :global
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2 !important;
}
.selectable :global .DayPicker-Day {
  border-radius: 0 !important;
}
.selectable :global .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.selectable :global .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.headerActions:hover i {
  color: rgba(0, 0, 0, 0.87) !important;
}
.headerActions:hover .green_icon {
  color: #21ba45 !important;
}

.headerActions .dropdowmMenu {
  min-width: 12em !important;
}

.download_header :global .header {
  background-color: #4a90e2 !important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.error :global .header {
  background-color: #da8284 !important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}

@media (min-width: 1024px) {
  .header_search {
    width: 145px !important;
    height: 24px;
    margin-left: 5px;
  }
  .checkboxAll {
    margin-left: 0.5rem;
    font-size: 12px !important;
  }
  .date_type {
    min-width: 110px !important;
    min-height: auto !important;
  }
  .date_range {
    min-width: 110px !important;
  }

  .selectable {
    width: inherit;
  }
  .selectable {
    width: 35rem;
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .header_search {
    width: 189px !important;
  }
  .selectable {
    width: 35rem;
    font-size: 12px;
  }
}

@media (min-width: 1281px) {
  .checkboxAll {
    margin-left: 2px;
  }

  .header_search {
    width: 170px !important;
    height: 24px;
    margin-left: 5px;
  }
  .date_type {
    height: 24px !important;
    min-width: 180px !important;
  }
  .date_range {
    min-width: 180px !important;
  }
  .date_range :global .input {
    width: 12rem;
  }
  .selectable {
    font-size: 13px;
  }
}
