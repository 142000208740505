.open_select_bottom {
  border-radius: 3px !important;
  border-color: var(--color-tuna) !important;
  color: var(--color-tuna);
  z-index: 1003 !important;
  font-display: 1003;
}
.mu_select_dd {
  --color-tuna: #373c43;
  --color-white: #fff;
  --color-pale-sky: #6d757b;
  --color-mandy: #e9596a;
  --color-shark: #2d3136;
  --color-black: #000;
  --color-seashell: #f1f1f1;
  --color-semantic-ui-border: rgba(34, 36, 38, 0.15);
  --line-height: 1em;
}
.mu_select_dd :global .dd__wrapper {
  position: relative;
}
.mu_select_dd :global .dd__opened {
  border-color: var(--color-tuna);
  color: var(--color-tuna);
}
.mu_select_dd :global .dd__disabled {
  border-color: var(--color-pale-sky);
  color: var(--color-pale-sky);
}
.mu_select_dd :global .dd__selectControl {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px;
  border: 1px solid var(--color-semantic-ui-border);
  border-radius: 3px;
  background-color: var(--color-white);
  color: var(--color-tuna);
  line-height: var(--line-height);
  cursor: pointer;
  overflow: hidden;
}
.mu_select_dd :global .dd__selected {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  overflow: hidden;
}
.mu_select_dd :global .dd__search {
  display: inline-block;
  align-items: center;
  flex-grow: 1;
  border: 1px solid transparent;
  outline: 0;
  min-height: var(--line-height);
  word-break: break-word;
}
.mu_select_dd :global .dd__selectedItem {
  display: flex;
  border: 1px solid transparent;
}
.mu_select_dd :global .dd__crossIcon {
  margin-left: 5px;
}
.mu_select_dd :global .dd__expandIcon {
  width: 9px;
  height: 15px;
  flex-shrink: 0;
  fill: var(--color-shark);
}
.mu_select_dd :global .dd__list {
  position: absolute !important;
  background-color: var(--color-white);
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  min-width: 100%;
  border: 1px solid var(--color-semantic-ui-border);
  border-radius: 3px;
  box-shadow: 0 3px 7px 0 rgba(var(--color-black), 0.08);
  will-change: transform;
}
.mu_select_dd :global .dd__listVirtualized {
  overflow-y: auto;
  overflow-x: hidden;
}
.mu_select_dd :global .dd__openTobottom {
  top: 100%;
  margin-top: 5px;
  height: 200px;
}
.mu_select_dd :global .dd__openTotop {
  bottom: 100%;
  margin-bottom: 5px;
}
.mu_select_dd :global .dd__optionVirtualized {
  position: absolute;
}
.mu_select_dd :global .dd__option {
  padding: 10px;
  line-height: var(--line-height);
  color: var(--color-tuna);
  white-space: nowrap;
  cursor: pointer;
}
.mu_select_dd :global .dd__option:hover {
  background-color: rgb(245, 245, 245) !important;
}
.mu_select_dd :global .dd__selectedOption {
  background-color: var(--color-seashell);
}
.mu_select_dd :global .dd__placeholder {
  color: rgb(202, 200, 200) !important;
}
.dd__error {
  border: 3px solid red !important;
}
