.custom_middle_panel_width {
  width: 100% !important;
}
.middle_panel_list {
  height: calc(100vh - 15.5em);
}
.middle_panel_list_0 {
  height: calc(100vh - 13em);
}
.toolbar_margin {
  margin-left: 30px !important;
}

@media (min-width: 1281px) {
  .toolbar_margin {
    margin-left: 0px !important;
  }
}
