/* .column.items_list.documents_list {
  width: calc(60% - 158px) !important;
} */

.bms_header {
  justify-content: flex-end;
}

.bms_doc_not_selected {
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dde1ee;
}

.bms_gutter_reset {
  padding-right: 0px !important;
  padding-top: 0 !important;
}

/* RADI premesteno iz global.css */
.items_list :global .ui.vertical.menu {
  width: 100%;
  max-height: calc(100vh - 14rem);
  overflow: auto;
  border: 1px solid #dde1ee;
  border-radius: 0.28571429rem;
}

.items_list :global .ui.form {
  font-size: 12px;
}

:global .header {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 12px;
}

.items_list :global .ui.vertical.menu .item,
.items_list :global .ui.vertical.menu .item:hover,
.items_list :global .ui.vertical.menu .item:hover + .item {
  padding: 20px 0px 25px;
  font-weight: 400;
}

.items_list :global .ui.vertical.menu .item.active {
  background: #f2f4fa;
  border-left: 2px solid #2185d0;
  padding: 20px 0px 25px;
  box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19);
}

.items_list :global .ui.vertical.menu {
  margin-top: 0.5rem !important;
}

.items_list :global .ui.vertical.menu span.ui.label.green {
  background-color: #b5d77e !important;
}

.items_list :global .ui.vertical.menu span.ui.label {
  color: #fff;
  font-size: 8px;
  text-transform: uppercase;
  border-radius: 2px;
  width: calc(100% + 12px);
  text-align: center;
  margin: 2px 1px 2px 1px !important;
}

.document_not_selected_paragraf {
  font-size: 40px;
  font-weight: bolder;
  text-transform: uppercase;
  color: #f7f7f9;
  text-align: center;
}

@media (min-width: 1281px) {
  .bms_gutter_reset {
    padding-right: 0px !important;
  }
  .items_list :global .ui.vertical.menu span.ui.label {
    width: 5rem !important;
  }
}

@media (min-width: 1481px) {
  .items_list :global .ui.vertical.menu span.ui.label {
    width: 6.7rem !important;
  }
}
