.accordition_container {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #dde1ee;
  width: 100% !important;
  margin-bottom: 15px;
  font-size: 12px !important;
}
.accordition_container :global .title,
.accordition_container :global .active.title {
  background: #f2f4fa !important;
  color: #575d62 !important;
  font-size: 0.97em !important;
}
.content_lines {
  padding: 1.15em !important;
  font-size: 0.9em !important;
}
.address_col :global .field {
  width: 50% !important;
}
.country {
  padding: 0% !important;
  height: 24px !important;
  padding-left: 10px !important;
}
.country :global .text {
  margin: auto !important;
  top: 50%;
  transform: translateY(-50%);
}
.checkbox_resize .ui.checkbox .label {
  font-size: 1em !important;
}
.country :global .menu {
  border-color: #dde1ee;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (min-width: 1024px) {
  .accordition_container {
    font-size: 12px !important;
  }
}
@media (min-width: 1281px) {
  .accordition_container {
    font-size: 13px !important;
  }
}
@media (min-width: 1600px) {
  .accordition_container {
    font-size: 14px !important;
  }
}
