/* bms_segment - ovo je pushable segment sto je ustvari sve - hvata sva 3 panela */
.bms_segment {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  height: 100vh;
}
/* bms_segment_zi - isto sto i bms segment samo kada su aktivni edit ili create */
.bms_segment_zi {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  z-index: 1002 !important;
}
/* bms_document_content - middle i desni panel unutar pushera */
.bms_document_content {
  margin-left: 0;
  max-height: calc(100vh - 2em) !important;
}
/* bms_zi - ovo je sam pusher kada je u editu ili create-u (pokriva sve) */
.bms_zi {
  z-index: 0 !important;
}
/* bms_fixed - ikonica za otvaranje menija na malim ekranima */
.bms_fixed {
  display: block !important;
  position: fixed !important;
  top: 5.9rem;
  left: 0px;
  width: 35px;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s width ease, 0.5s transform ease !important;
  z-index: 100 !important;
  padding: 0.7rem !important;
}
/* bms_fixed - ikonica za otvaranje menija na malim ekranima */
.bms_fixed i {
  margin-right: 1.3rem !important;
}
/* bms_fixed - ikonica za otvaranje menija na malim ekranima */
.bms_fixed span {
  font-size: 13px;
}
/* bms_zindex - ovo je da sakrije ikonicu kada se otvori sidebar na malim ekranima */
.bms_zindex {
  z-index: 0 !important;
}
/* bms_document_content - middle i desni panel unutar pushera */
.bms_document_content :global .ui.vertical.menu {
  max-height: calc(100vh - 10rem);
  width: 100%;
  border: 1px solid #dde1ee;
  border-radius: 0.28571429rem;
  box-shadow: none !important;
  margin-bottom: 5px !important;
}
/* bms_document_content - middle i desni panel unutar pushera */
.bms_document_content :global .ui.middle.aligned.divided.list {
  width: 100%;
  border: 1px solid #dde1ee;
  border-radius: 0.28571429rem;
}
/* End Modal why document can not be deleted */
.right_panel_padding {
  /* padding-left: 5px !important; */
}
.bms_hide {
  visibility: hidden;
}

@media (min-width: 1281px) {
  /* bms_document_content - middle i desni panel unutar pushera */
  .bms_document_content {
    margin-left: 260px;
  }
  /* bms_fixed - ikonica za otvaranje menija na malim ekranima */
  .bms_fixed {
    visibility: hidden;
  }
  /* bms_zi - ovo je sam pusher kada je u editu ili create-u (pokriva sve) */
  .bms_zi {
    z-index: 1002 !important;
  }
}
.change_middAndRig_margin {
  margin-left: 0px;
}
