.bms_scan_documents_item {
  padding: 20px 15px 25px !important;
}

.scaned_list_select_button {
  padding: 6px 18px !important;
  font-size: 12px !important;
  margin-left: 0.313rem !important;
  height: 25px;
  background-color: #e1eef9 !important;
}

.scaned_list_select_button:hover {
  color: #fff !important;
  background-color: #2185d0 !important;
}
.scaned_list_select_button :global .icon:hover {
  color: #fff !important;
  /* background-color: #2185d0 !important; */
}
