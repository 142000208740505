.headerOnPay {
  margin-top: 1px;
  font-size: 13px !important;
}

.headerOnNotPay {
  margin-top: 2.5rem;
  border-radius: 5px !important;
  border: 1px solid #f2f4fa !important;
  font-size: 13px !important;
}

.right_panel_item {
  background: #f9fafe;
  font-size: 0.9375rem;
  font-weight: 900;
}

.right_panel_item i {
  cursor: pointer !important;
}

.right_panel_item i:hover {
  cursor: pointer !important;
  color: #2185d0;
}

.right_panel_item :global .trash.alternate:hover {
  cursor: pointer !important;
  color: #d9534f;
}

#document_right_header :global .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f4fa;
  font-size: 1rem;
  font-weight: 900;
  margin-top: 2.4rem;
  /* padding: 0.5625rem 0.625rem; */
  padding: 1.1rem;
  border: 1px solid #f2f4fa;
  border-radius: 5px;
}

.bms_header {
  background: #2185d0;
  padding: 15px 10px 15px 25px;
  /* margin: 16px 0 10px 0; */
  margin-top: 2.4rem !important;
  margin-bottom: 0.8rem !important;
  text-align: left;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}

.header_pop_up {
  margin-bottom: 5px !important;
  margin-left: 95% !important;
}

.header_icon {
  font-size: 1.2em !important;
}

.icon_group {
  cursor: pointer;
  font-size: 1.2em !important;
}

.icon_group_details {
  cursor: pointer;
  font-size: 1.2em !important;
}

.icon_trash {
  color: #333;
  font-size: 1.2em !important;
}

.right_panel_msg {
  margin-top: 17% !important;
}

.column_height {
  height: calc(100vh - 5rem) !important;
  padding-right: 5px !important;
}

.export_dropdown{
  font-weight: normal !important;
  margin-right: 2px !important;
  font-size: 1.2em !important;
}

.pdf_icon{
  font-size: 1.1em !important;
}

@media (min-width: 1024px) {
  #document_right_header :global .content {
    height: 3.9rem;
    border-radius: 5px;
  }
  .header_pop_up {
    margin-bottom: 5px !important;
    margin-left: 91% !important;
  }
  .right_panel_msg {
    margin-top: 26% !important;
  }
}

@media (min-width: 1281px) {
  .headerOnPay {
    margin-top: 0.7rem !important;
    font-size: 17px !important;
  }
  .headerOnNotPay {
    font-size: 17px !important;
  }
  #document_right_header :global .content {
    height: 3.9rem;
    border-radius: 5px;
  }
  .header_pop_up {
    margin-bottom: 5px !important;
    margin-left: 93% !important;
  }
  .right_panel_msg {
    margin-top: 17% !important;
  }
}
