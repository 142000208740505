.compareModal {
  width: 95% !important;
}

.compareModalColumn {
  width: 50% !important;
}

.showDiagramButton {
  float: right;
  font-size: 12px;
}
.document_flow {
  width: 10000px;
  overflow-x: scroll;
}
.bms_diagram_modal {
  width: 80vw !important ;
}

.bms_diagram_modal :global i.close.icon {
  top: -0.5rem !important;
}

.bms_diagram_modal :global .file.pdf.massive.icon {
  font-size: 3em;
  margin-top: 10%;
  text-align: center;
}

.bms_diagram_modal :global .centered .column {
  text-align: center !important;
}

.bms_diagram_modal :global .column .checkbox {
  padding-bottom: 10px;
}

.bms_diagram_modal :global .ckLXmP {
  overflow: initial !important;
  width: auto;
}

.bms_diagram_modal :global .sc-htpNat {
  min-width: 150px !important;
}
