.bms_segment {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  height: 100vh;
}
.bms_segment_zi {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  z-index: 1002 !important;
  height: calc(100vh - 5rem) !important;
  height: inherit !important;
}
.bms_partners_content {
  margin-left: 0;
}
.bms_partners_content :global .ui.vertical.menu {
  max-height: calc(100vh - 13.5rem);
  margin-bottom: 5px !important;
  overflow: auto;
  width: 100%;
  border: 1px solid #dde1ee;
  border-radius: 0.28571429rem;
}
.pag_list_size :global .ui.vertical.menu {
  max-height: calc(100vh - 16.5rem);
}
.bms_zi {
  z-index: 0 !important;
}
.bms_fixed {
  display: block !important;
  position: fixed !important;
  top: 5.9rem;
  left: 0px;
  width: 35px;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s width ease, 0.5s transform ease !important;
  z-index: 100 !important;
  padding: 0.7rem !important;
}
.bms_fixed i {
  margin-right: 1.3rem !important;
}
.bms_fixed span {
  font-size: 13px;
}
.bms_zindex {
  z-index: 0 !important;
}
.clear_both {
  clear: both;
  display: block;
}
.partners_notification :global .ui-alerts.bottom-right {
  bottom: 6rem !important;
}

@media (min-width: 1281px) {
  .bms_partners_content {
    margin-left: 260px;
  }
  .bms_fixed {
    visibility: hidden;
  }
  .bms_zi {
    z-index: 1002 !important;
  }
  .bms_segment_zi {
    height: inherit !important;
  }

  /* Start Modal why contact can not be deleted */
  .list_of_items {
    margin: 15px 1px 10px 1px !important;
  }
  .list_of_items :global .ui.vertical.menu span.ui.label {
    color: #fff;
    font-size: 8px;
    text-transform: uppercase;
    border-radius: 2px;
    width: calc(100% + 12px);
    text-align: center;
    margin: 2px 1px 2px 1px !important;
  }
  .status_aligment :global :nth-child(3) {
    padding-left: 25px !important;
  }
  .status_aligment :global span {
    color: white !important;
    margin-bottom: 3px !important;
    text-align: center;
  }
  .status_aligment :global .row {
    border-bottom: 1px solid #cccccc !important;
  }
  /* End Modal why contact can not be deleted */
}
