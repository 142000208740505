.right_panel {
  margin-left: 0px;
  width: 50% !important;
}
.min_max_segment {
  padding: 0px !important;
  margin: 0px !important;
}
.min_block {
  display: inline-block;
}
.min_label {
  margin: 0px 15px 0px 6px !important;
}
.max_block {
  display: inline-block;
}
.max_label {
  margin: 0px 0px 0px 6px !important;
}
.roles_details_title {
  display: inline-block !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.wrap_text {
  word-break: break-word;
}
