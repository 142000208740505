.toolBarForm {
  font-size: 12px !important;
}

.checkboxAll {
  margin: 0px 5px;
  font-size: 12px !important;
}

.toolbar_button {
  padding: 6px 18px !important;
  font-size: 12px !important;
  margin-left: 0.313rem !important;
}

.toolBarForm :global .ui.button.disabled {
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset !important;
}

.header_search {
  width: 145px !important;
  margin-left: 5px;
  font-size: 0.9rem;
}

.headerActions {
  padding: 0.375rem 1.125rem !important;
  margin-left:5px !important;
}

.headerActions:hover i {
  color: rgba(0, 0, 0, 0.87) !important;
}
.headerActions:hover .green_icon {
  color: #21ba45 !important;
}

.headerActions .dropdowmMenu {
  min-width: 12em !important;
}

.toolbar {
  display: flex;
}


@media (min-width: 1024px) {
  .header_search {
    width: 145px !important;
    height: 24px;
    margin-left: 5px;
  }
  .checkboxAll {
    margin-left: 0.5rem;
    font-size: 12px !important;
  }
}

@media (min-width: 1200px) {
  .header_search {
    width: 189px !important;
  }
}

@media (min-width: 1281px) {
  .checkboxAll {
    margin-left: 2px;
  }

  .header_search {
    width: 170px !important;
    height: 24px;
    margin-left: 5px;
  }
}
