.bms_header {
  background: #2185d0;
  padding: 15px 10px 15px 25px;
  margin: 10px 0 10px;
  text-align: left;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}

/* Button group Upload and DragAndDrop */
.upload_new_document {
  width: 100%;
  height: 30%;
  border: 1px solid #dde1ee;
  border-radius: 3px;
  margin-top: 15px;
}

.upload_new_document.ui.buttons {
  width: 100%;
  height: 30%;
  border: 1px solid #dde1ee;
  border-radius: 3px;
  margin-top: 15px;
}

.upload_new_document :global .ui.button {
  background-color: #f2f4fa;
  color: #575d62;
  width: 33%;
}

.upload_new_document :global .ui.button:hover {
  color: #2185d0;
}

:global .ui.buttons i {
  display: block !important;
  margin: 0 auto 30px !important;
  font-size: 300%;
  color: #8b8f9d;
}

:global .ui.button:hover i {
  color: #2185d0;
}

.upload_new_document :global .ui.buttons .or {
  width: 0;
  height: auto;
  border-left: 1px solid #dde1ee;
}
.upload_new_document :global .ui.buttons .or:before {
  background-color: #f2f4fa;
  color: #575d62;
  text-transform: uppercase;
}

.cancel_btn {
  margin-top: 10px !important;
  box-shadow: 0px 0px 0px 1px #2185d0 inset !important;
  background: transparent none !important;
  color: #2185d0 !important;
}

.cancel_btn:hover {
  background-color: #2185d0 !important;
  color: #f2f4fa !important;
}

.upload_input {
  display: none;
}

/* End Button group */
