.bms_document_details_container {
  height: calc(100vh - 14rem);
  overflow: auto;
}
.margin_top_zero {
  margin-top: 0 !important;
}
.padding_top_zero {
  padding-top: 0 !important;
}
.bms_details_no_accordion,
.bms_details_comments {
  font-size: 15px !important;
  font-weight: 900;
  margin-bottom: 6px !important;
  margin-top: 5px !important;
  border-radius: 5px;
  background: #f2f4fa;
  padding: 11px 10px !important;
}

.bms_details_comments {
  background: #f9fafe !important;
}
.dms_accordiion_title {
  border-radius: 5px;
  background: #f9fafe !important;
  padding: 11px 10px !important;
  font-weight: 900 !important;
  color: #575d62 !important;
  font-size: 15px !important;
}

.no_data_msg {
  margin: 0.2em !important;
  width: 98% !important;
}

.dms_accordiion_title i {
  float: right;
}

.main_column_padding {
  padding-left: 0px !important;
  padding-right: 5px !important;
}
.payment_status_grid {
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 0em !important;
}
.status_col_label {
  padding-left: 10px !important;
}
.wrap_column {
  word-wrap: break-word;
  max-width: 60%;
  float: right;
}
.wrap_table_colum {
  word-wrap: break-word;
  max-width: 200px;
}
.wrap_column_description {
  word-wrap: break-word;
  padding: 5px;
}
.bms_list {
  border: 0 !important;
  margin: 0 0 3px 0 !important;
}
.bms_list :global .item {
  padding: 15px 10px !important;
  font-size: 13px !important;
}

.bms_list_accordion {
  border: 0 !important;
  margin: 0 0 3px 0 !important;
}

.bms_list_accordion :global .item {
  padding: 0.8em !important;
  font-size: 13px !important;
}

.details_accordion {
  padding-top: 2px;
  padding-bottom: 2px;
}

.currency {
  float: right;
}

.allButtons {
  margin-bottom: 2px !important;
  width: 7rem;
  margin-left: 2em !important;
}
.labelCreatedAt {
  width: 6rem;
  text-align: center;
  color: white !important;
  background-color: #9b8e7c !important;
}
.labelChangedAt {
  width: 6rem;
  text-align: center;
  color: white !important;
  background-color: #b7b1e9 !important;
}
.labelCompletePay {
  width: 6rem;
  color: white !important;
  background-color: #ba55d3 !important;
  text-align: center;
}
.labelStatusApproved {
  background-color: #b5d77e !important;
  color: white !important;
  width: 6rem;
  text-align: center;
  margin-left: 2em !important;
}
.labelStatusVerified {
  background-color: #77d1a1 !important;
  color: white !important;
  width: 6rem !important;
  text-align: center;
  margin-left: 2em !important;
}
.labelStatusDeclined {
  background-color: #7ed7d3 !important;
  color: white !important;
  width: 6rem !important;
  text-align: center;
}
.labelStatusUnresolved {
  background-color: #a9a9a9 !important;
  color: white !important;
  width: 6rem !important;
  font-size: 10px !important;
  text-align: center;
  padding: 0.6rem;
  margin-left: 2em !important;
}
.labelStatusImpotred,
.labelStatusPDFCreated,
.labelStatusSent,
.labelStatusPaid,
.labelStatusDunning {
  color: white !important;
  width: 6rem !important;
  text-align: center;
  margin-left: 2em !important;
  font-size: 10px !important;
}
.labelStatusImpotred {
  background-color: #77c5d1 !important;
}
.labelStatusPDFCreated {
  background-color: #66acb7 !important;
}
.labelStatusSent {
  background-color: #4d8891 !important;
}
.labelStatusPaid {
  background-color: #3b717a !important;
}
.labelStatusDunning {
  background-color: #da8284 !important;
}

.labelCreatedAt,
.labelChangedAt,
.labelCompletePay,
.labelStatusApproved,
.labelStatusVerified,
.labelStatusDeclined,
.labelStatusUnresolved,
.allButtons {
  font-size: 10px !important;
}
.labelText {
  vertical-align: middle;
}

.bms_document_details_container :global .ui.message:first-child {
  margin: 1em 0;
}
.bms_document_details_container .empty {
  font-size: 3em !important;
}
.bms_document_details_container i {
  cursor: pointer;
  font-size: 1.2em !important;
}
.bms_document_details_container button {
  font-size: 10px !important;
}
.bms_item_table {
  font-size: 12px !important;
}
.details_partner {
  word-wrap: break-word;
  max-width: calc(100% - 10rem);
}
.center_cell {
  text-align: center !important;
}

/* Start Pop-up modal style */
.dialogHeader {
  margin-top: 0px !important;
  color: white !important;
}
:global .ui.positive.right.labeled.button {
  text-transform: none !important;
}

/* End Pop-up modal style */
#document_preview_center {
  text-align: center;
}
.documentPreviewDetails {
  width: 100%;
  height: 100vh;
}
.documentImagePreviewContainer {
  max-width: 100% !important;
  max-height: 100% !important;
  overflow: hidden !important;
}
.modal_text_area {
  width: 100%;
  resize: none;
}
.payment_grid {
  margin-top: 30px;
}
tbody tr td {
  max-width: 200px;
  word-wrap: break-word;
}
.header_text {
  float: left !important;
}
.edit_link_docs_icon {
  float: right !important;
}
.formAddButton {
  float: right;
}
.formAddButton :hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
}
:global i.mini.icon {
  font-size: 0.88em !important;
}

.custom_disabled {
  opacity: 0.4 !important;
}

.custom_disabled:hover {
  cursor: default !important;
}

.imported {
  background-color: #77c5d1 !important;
  color: white !important;
}

.prf_created {
  background-color: #66acb7 !important;
}

.sent {
  background-color: #4d8891 !important;
}

.paid {
  background-color: #3b717a !important;
}
.dunning {
  background-color: #da8284 !important;
}

.table_note {
  text-align: end !important;
}

@media (min-width: 1024px) {
  .allButtons,
  .labelCreatedAt,
  .labelChangedAt,
  .labelCompletePay,
  .labelStatusApproved,
  .labelStatusVerified,
  .labelStatusDeclined,
  .labelStatusUnresolved,
  .labelStatusImpotred,
  .labelStatusPDFCreated,
  .labelStatusSent,
  .labelStatusPaid,
  .labelStatusDunning {
    padding: 0.6rem 0.2rem !important;
    word-wrap: break-word;
  }

  .table_width {
    width: 34vw !important;
    overflow: auto;
  }
}

@media (min-width: 1240px) {
  .allButtons,
  .labelCreatedAt,
  .labelChangedAt,
  .labelCompletePay,
  .labelStatusApproved,
  .labelStatusVerified,
  .labelStatusDeclined,
  .labelStatusUnresolved,
  .labelStatusImpotred,
  .labelStatusPDFCreated,
  .labelStatusSent,
  .labelStatusPaid,
  .labelStatusDunning {
    padding: 0.6rem !important;
    width: 7rem !important;
  }
}

@media (min-width: 1281px) {
  .allButtons,
  .labelCreatedAt,
  .labelChangedAt,
  .labelCompletePay,
  .labelStatusApproved,
  .labelStatusVerified,
  .labelStatusDeclined,
  .labelStatusUnresolved,
  .labelStatusImpotred,
  .labelStatusPDFCreated,
  .labelStatusSent,
  .labelStatusPaid,
  .labelStatusDunning {
    width: 7rem !important;
    font-size: 0.8rem !important;
    padding: 0.6rem !important;
  }

  .manualPay {
    margin-left: 0 !important;
  }

  .labelStatusVerified,
  .labelStatusApproved,
  .labelStatusImpotred,
  .labelStatusPDFCreated,
  .labelStatusSent,
  .labelStatusPaid,
  .labelStatusDunning {
    margin-left: 0 !important;
  }
  .labelStatusUnresolved {
    width: 8rem !important;
    margin-left: 0 !important;
  }
  .bms_document_details_container button {
    font-size: 11px !important;
  }

  .bms_document_details_container i {
    font-size: 1.2em !important;
  }
  .details_partner {
    max-width: calc(100% - 8.5rem);
  }
  .status_padding_reset {
    padding-left: 1rem !important;
  }
  .table_width {
    width: 100% !important;
  }
}

@media (min-width: 1500px) {
  .allButtons {
    margin-left: 0 !important;
  }
}
