.bms_header {
  background: #2185d0;
  padding: 15px 10px 15px 25px;
  margin: 10px 0 10px;
  text-align: left;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}

.item_details_container {
  margin-right: 1.3rem;
}

.company_details_container_account {
  margin-top: 1.5625rem;
}

.str_lable {
  margin-bottom: 0.625rem !important;
}

.btn_group {
  position: absolute;
  width: calc(100% - 20px);
  bottom: 4rem;
  right: 20px;
  text-align: right;
}

/*Save And SaveAndNew Buttons*/
/*shadow above save */
.buttons_container_shadow {
  position: relative;
  z-index: 1;
  margin-top: -30px;
  margin-bottom: 20px;
  box-shadow: 0 -15px 18px -20px rgba(0, 0, 0, 0.7) inset;
  border-bottom: 1px solid #e9e9e9;
  height: 30px;
  margin-left: -1px;
  margin-right: -1px;
}

/*End Buttons group save and new */

@media (min-width: 1024px) {
  .btn_group {
    bottom: 5rem;
  }
}

@media (min-width: 1281px) {
  .btn_group {
    bottom: 4.5em;
  }
}
