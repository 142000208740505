.container_list {
  width: 100% !important;
}
/* .container_list :global a {
  padding: 20px 0px 25px !important;
  font-weight: 400 !important;
} */
.container_list :global a.active {
  background: #f2f4fa;
  border-left: 2px solid #2185d0;
  padding: 20px 0px 25px;
  box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19);
}
.segment_padding {
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
}
.unauthorised_page {
  overflow: hidden;
}
