.small_input {
  width: 3.5em !important;
  opacity: 1 !important;
}
.middle_input {
  width: 5.2em !important;
  opacity: 1 !important;
}
.subtotal_table {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  width: 99% !important;
  margin-top: 1px !important;
  font-size: 0.8em !important;
}
.subtotal_table > thead > tr > th {
  padding: 5px 8px !important;
}

.subtotal_table > thead > tr > th > i {
  font-size: 11px;
}
.default_cell {
  padding: 5px 2px 5px 2px !important;
}
.edit_icon,
.minus_icon,
.edit_check,
.edit_cancel {
  cursor: pointer;
}
.edit_icon:hover,
.edit_cancel:hover,
.edit_check:hover,
.minus_icon:hover {
  opacity: 0.5 !important;
}
.subtotal_container {
  overflow-x: scroll;
}
.plus_button {
  background: none !important;
}
.plus_button i:hover {
  color: rgb(84, 157, 199) !important;
}

@media (min-width: 1024px) {
  .minus_icon {
    margin-right: 5px !important;
  }

  .edit_icon,
  .edit_check,
  .edit_cancel {
    margin-right: 5px !important;
  }
}

@media (min-width: 1230px) {
  .subtotal_container {
    overflow-x: inherit;
  }
  .edit_check {
    font-weight: bold;
  }
}

@media (min-width: 1281px) {
  .minus_icon {
    margin-right: 0 !important;
    font-size: 14px !important;
    float: inherit !important;
  }

  .edit_icon,
  .edit_check,
  .edit_cancel {
    margin-right: 0 !important;
    font-size: 14px !important;
    float: inherit !important;
  }
  .small_input {
    width: 3.5rem !important;
  }
  .middle_input {
    width: 5.5rem !important;
  }
  .subtotal_table {
    font-size: 1em !important;
  }
}
