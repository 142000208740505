h5 {
  font-weight: 700;
}
body ::-webkit-scrollbar {
  width: 4px !important;
}
/* .middle_panel {
  padding-right: 0px !important;
} */
.bms_before_header {
  margin-top: 0 !important;
  margin-left: 2.8rem;
}
.header {
  font-size: 12px;
  min-width: 0;
  width: 100%;
  margin-left: 2.8rem;
}
.form_header {
  position: relative !important;
}
.header_search {
  left: 5px;
  color: #8b8f9d;

  /* height: 24px; */
  top: 50%;
  transform: translate(0%, 5%) !important;
}
.header_search > div input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.search_input {
  position: relative;
  height: 24px !important;
  margin-top: 0px !important;
  border: 1px;
}
.table_headerContactList {
  background: #f2f4fa;
  margin-top: 0.6rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  font-size: 12px;
  padding-right: 10px !important;
  border: 1px solid #f2f4fa;
  border-radius: 5px;
}

.bms_before_header :global .ui.form .ui.checkbox {
  font-size: 12px;
  padding-right: 20px;
}

.bms_basic_buttons {
  font-size: 12px !important;
  padding: 6px 18px !important;
  margin-left: 5px !important;
}

.ui.input > input {
  border: 1px solid #dde1ee;
}

.trigger_style {
  display: flex;
}
.product_dropname {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}

.bms_header_small_screen {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.bms_header_small_screen h5 {
  margin: 0;
}
@media (min-width: 1281px) {
  .bms_before_header {
    margin-left: 0;
  }

  .header {
    margin-left: 0;
  }

  .bms_header_small_screen {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  h5 {
    font-weight: 750;
  }
}
@media (min-width: 1600px) {
  h5 {
    font-weight: 800;
  }
}
