.formAddButton {
  padding-left: 30% !important;
  margin: auto;
  order: 3 !important;
}
.formAddButton :hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
}
.title {
  display: grid;
  grid-template-columns: 92% 8%;
  background-color: #6fb5eb !important;
  color: white !important;
  border: 0 !important;
  font-size: 0.97em !important;
  padding: 0.75em 1em !important;
}
.message_block {
  font-size: 0.8em !important;
}
@media (min-width: 1281px) {
  .message_block {
    font-size: 0.9em !important;
  }
}
@media (min-width: 1600px) {
  .message_block {
    font-size: 1em !important;
  }
}
