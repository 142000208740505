.resources_segment {
  font-size: 14px !important;
  margin-bottom: 3px !important;
  color: rgb(87, 93, 98);
}
.resources_segment_details {
  font-size: 14px !important;
  background: #f2f4fa !important;
  color: rgb(87, 93, 98);
  margin: 0rem 4px !important;
  width: 97% !important;
}
.resources_partners {
  font-size: 14px !important;
  margin: 0rem 4px !important;
  width: 97% !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
}
.resources_segment > strong {
  font-size: 1rem !important;
  display: inline-block;
  text-overflow: ellipsis !important;
  width: 25%;
  white-space: nowrap !important;
  overflow: hidden !important;
}
.resources_segment_position {
  font-size: 0.8rem !important;

  display: block;
  float: right;
}
.projectDetailsResources {
  margin: 1rem 4px;
  width: 97% !important;
}
.title_resources {
  background-color: #f2f4fa !important;
}
.resources_segment_label_max {
  margin: 0 0 0 6px !important;
}
.resources_segment_label_min {
  margin: 0 5px 0 6px !important;
}
.partner_dropdown {
  margin-bottom: 3px;
}

.search_dropdown {
  background-color: #ffff !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.search_dropdown .text {
  opacity: 0.4;
}

@media (min-width: 1560px) {
  .resources_segment > strong {
    width: 35%;
  }
  .resources_segment_label_min {
    margin: 0 7px 0 6px !important;
  }
  .resources_segment_position {
    font-size: 0.9rem !important;
  }
}
@media (min-width: 1680px) {
  .resources_segment > strong {
    width: 40%;
  }
  .resources_segment_label_min {
    margin: 0 10px 0 6px !important;
  }
  .resources_segment_position {
    font-size: 1rem !important;
  }
}
