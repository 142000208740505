.bms_header {
  background: #2185d0;
  padding: 15px 10px 15px 25px;
  margin: 10px 0 10px;
  text-align: left;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}

.item_details_container {
  margin-right: 1.3rem;
}

.company_details_container_account {
  margin-top: 1.5625rem;
}

.str_lable {
  margin-bottom: 0.625rem !important;
}
.str_lable_mail {
  margin-bottom: 0.625rem !important;
  margin-top: 1rem;
}

.btn_group {
  position: absolute;
  width: calc(100% - 20px);
  bottom: 4rem;
  right: 20px;
  text-align: right;
}

/*Save And SaveAndNew Buttons*/
/*shadow above save */
.buttons_container_shadow {
  position: relative;
  z-index: 1;
  margin-top: -30px;
  margin-bottom: 20px;
  box-shadow: 0 -15px 18px -20px rgba(0, 0, 0, 0.7) inset;
  border-bottom: 1px solid #e9e9e9;
  height: 30px;
  margin-left: -1px;
  margin-right: -1px;
}

/*Start Buttons group save and new (without cancel) */
.buttons_container :global .ui.buttons {
  width: 30%;

  margin-bottom: 15px;
}

.buttons_container :global .ui.button {
  background: #baebd1;
  color: #3cb776;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  width: 30%;
  height: 50px;
  padding-left: 0;
  padding-right: 0;
}

.buttons_container :global .ui.button:hover {
  background: #abe6c7;
}

.buttons_container :global .ui.positive.button {
  background: #3cb776;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.buttons_container :global .ui.positive.button:hover {
  background: #23a35f;
}

/*Cancel button*/
.buttons_container :global .negative.ui.button {
  background: #f3d4d3;
  color: #d9534f;
  float: right;
  margin-right: 0;
}

/*Cancel button*/
.buttons_container :global .negative.ui.button:hover {
  background: #e6bcbb;
}
/*End Buttons group save and new */

@media (min-width: 1024px) {
  .btn_group {
    bottom: 5rem;
  }
}

@media (min-width: 1281px) {
  .btn_group {
    bottom: 4rem;
  }
}
