.date_range {
  padding: 5px !important;
  margin-left: 0.313rem;
  font-size: 11px !important;
}

.date_range :global .input {
  padding-left: 5px !important;
  height: 24px !important;
  min-height: 24px !important;
  width: 14rem;
}

.selectable
  :global
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2 !important;
}
.selectable :global .DayPicker-Day {
  border-radius: 0 !important;
}
.selectable :global .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.selectable :global .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

@media (min-width: 1024px) {
  .date_range {
    min-width: 110px !important;
  }
  .selectable {
    width: inherit;
  }
  .selectable {
    width: 35rem;
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .selectable {
    width: 35rem;
    font-size: 12px;
  }
}

@media (min-width: 1281px) {
  .date_range {
    min-width: 180px !important;
  }
  .date_range :global .input {
    width: 12rem;
  }
  .selectable {
    font-size: 13px;
  }
}
