body {
  padding: 0;
  margin: 0;
  height: 100%;
  color: #575d62;
  overflow-y: hidden;
}

h5 {
  font-weight: 800;
}

/*It is used for lists, filters and nav bar*/
.ui.menu .item {
  color: #575d62;
}

/*All , Projects*/
body ::-webkit-scrollbar {
  width: 4px !important;
}

body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 5px;
}

/* header */
/*AppNavBar*/
.ui.menu.header_menu {
  position: relative;
  z-index: 103 !important;
  border: 0;
  border-bottom: 1px solid #e9e9e9;
  border-radius: 0;
  -webkit-box-shadow: 0px 1px 15px #e9e9e9;
  box-shadow: 0px 1px 15px #e9e9e9;
  height: 5em;
}

/*AppNavBar - items */
.header_menu.ui.menu .item {
  font-size: 15px;
}
/*AppNavBar - items */
.header_menu.ui.menu a.item:hover,
.header_menu.ui.menu div.ui.dropdown.item:hover {
  background-color: #f2f4fa !important;
}
/*AppNavBar - items */
.header_menu.ui.menu .active.item {
  background: transparent;
  font-weight: 900;
  color: #24272a;
}

/*AppNavBar - line beside user details in nav bar */
.header_menu.ui.menu .item:before {
  width: 0px;
}

/*AppNavBar - logo */
.header_menu.ui.menu img.logo {
  margin-right: 10px;
}

/*AppNavBar - items in dropdowns */
.header_menu.ui.menu .ui.dropdown.item .menu a.item {
  font-size: 15px !important;
  color: #575d62 !important;
  padding: 20px 30px !important;
}

/*AppNavBar - hover items in dropdowns  */
.header_menu.ui.menu .ui.dropdown.item .menu a.item:hover {
  background-color: #f2f4fa !important;
}

i.disabled.icon {
  cursor: auto;
}

/* Export DatePicker */
.DayPicker-Month:nth-child(1) .DayPicker-Caption div:nth-child(3) {
  display: none !important;
}
.DayPicker-Month:nth-child(2) .DayPicker-Caption div:nth-child(2) {
  display: none !important;
}
.DayPicker-Month:nth-child(2) .DayPicker-Caption div:nth-child(3) {
  margin-bottom: 1.5rem;
}
/* Export DatePicker END */
