#document_preview_center {
  text-align: center;
  height: 67vh;
  overflow: auto;
}

.documentPreviewDetails {
  width: 100%;
  height: 66vh;
}

.documentImagePreviewContainer {
  max-width: 100% !important;
  max-height: 100% !important;
  overflow: hidden !important;
}

@media (min-width: 1281px) {
  #document_preview_center {
    height: 80vh;
  }
  .documentPreviewDetails {
    height: 79vh;
  }
}
