.bms_segment {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  height: 100vh;
}

.bms_segment_zi {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  z-index: 1002 !important;
}
.bms_statuses_content {
  margin-left: 0;
  padding: 0 1rem;
}

.bms_zi {
  z-index: 0 !important;
  height: 100vh;
}
.bms_zindex {
  z-index: 0 !important;
  left: -200px !important
  ;
}

.bms_fixed {
  display: block !important;
  position: fixed !important;
  top: 5.9rem;
  left: 0px;
  width: 35px;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s width ease, 0.5s transform ease !important;
  z-index: 100 !important;
  padding: 0.7rem !important;
}

.bms_fixed i {
  margin-right: 1.3rem !important;
}

.bms_fixed span {
  font-size: 13px;
}

.bms_statuses_content :global .ui.vertical.menu {
  width: 100%;
  border: 1px solid #dde1ee !important;
  border-radius: 0.28571429rem;
}

.bms_statuses_content :global .grid {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.bms_statuses_content :global .ui.middle.aligned.divided.list {
  width: 100%;
  border: 1px solid #dde1ee;
  border-radius: 0.28571429rem;
}
.pusher_height {
  height: 100vh;
}

.statuses_toaster :global .ui.info.message {
  bottom: 6rem !important;
}

.statuses_toaster :global .ui.floating.icon.warning.message {
  bottom: 6rem !important;
}

@media (min-width: 1281px) {
  .bms_statuses_content {
    margin-left: 260px;
  }

  .bms_fixed {
    visibility: hidden;
  }

  .bms_zi {
    z-index: 1002 !important;
  }
}
