.bold {
  font-weight: bolder;
}

.dropdownMargin {
  min-width: 16em !important;
  flex: 1 0 auto;
  padding: 0.67857143em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
}
.dropdownMargin i {
  padding-left: 5.6rem;
}
.listItemMargin {
  margin-bottom: 1.7rem !important;
}
.paymentFormStyle {
  padding: 8px;
  border: 1px solid #dde1ee;
  border-radius: 0.28571429rem;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  font-size: 11px;
}
.paymentFormStyle :global .ui.selection.dropdown > .dropdown.icon {
  padding-top: 0.5rem;
}
.paymentFormStyle :global .item {
  margin-bottom: 1px;
}

.paymentFormStyle :global .header {
  margin-top: 0 !important;
  margin-bottom: 3px !important;
}
.rowPadding {
  padding-top: 0 !important;
  background: #f9fafb;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}
.row_bottom {
  padding-bottom: 2px !important;
}
.pickerPosition :global .DayPicker-Months {
  font-size: 10px !important;
}
.payment_labele {
  position: absolute !important;
  z-index: 100 !important;
}
.project_toaster :global .ui.info.message {
  bottom: 6rem !important;
}
.project_toaster :global .ui.floating.icon.warning.message {
  bottom: 6rem !important;
}
div.scrollingBar::-webkit-scrollbar {
  width: 15px !important;
}
.buttonLeft {
  text-align: left !important;
}
.listItemMarginSmall {
  margin-bottom: 1rem !important;
}

.manualPaymentFormStyle {
  padding: 1.5em;
  border: 1px solid #dde1ee;
  border-radius: 0.28571429rem;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  font-size: 11px;
}

.btn_column {
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.globaldatedown_field :global .ui.labeled.icon.button {
  background: #fff !important;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
}

@media (min-width: 1024px) {
  .listItemMargin {
    margin-bottom: 1.2rem !important;
  }
  .paymentFormStyle {
    min-height: 20rem;
  }
}

@media (min-width: 1281px) {
  .dropdownMargin {
    min-width: 14em !important;
  }
  .dropdownMargin i {
    padding-left: 4.8rem;
  }

  .paymentFormStyle {
    font-size: 13px;
    padding: 20px;
  }
  .paymentFormStyle :global .ui.selection.dropdown > .dropdown.icon {
    padding-top: 0.8rem;
  }
  .buttonWidth {
    width: 10rem;
    font-size: 13px !important;
  }
  .paymentFormStyle :global .item {
    margin-bottom: 5px;
  }
  .listItemMargin {
    margin-bottom: 1.7rem !important;
  }
  .headerMargine {
    margin-bottom: 10px !important;
  }
  .pickerPosition {
    left: 0 !important;
  }

  div.scrollingBar::-webkit-scrollbar {
    width: 15px !important;
  }

  .listItemMarginSmall {
    margin-bottom: 0.5rem !important;
  }
}
