/************  sa TransactionsList.css **************/

.items_list :global .ui.vertical.menu {
  width: 100%;
  max-height: calc(100vh - 16rem) !important;
  overflow: auto;
  border: 1px solid #dde1ee;
  border-radius: 0.28571429rem;
  box-shadow: none !important;
  margin-bottom: 5px !important;
}

.transactions_allaccounts :global i {
  padding-top: 5px !important;
}

.bms_transaction_list :global .sales {
  border: 1px solid #3cb776;
  border-radius: 0.28571429rem;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 10rem;
  height: 24px;
  padding-top: 2px;
}

.bms_transaction_list :global .sales strong {
  font-size: 12px;
}

.bms_transaction_list :global .sales span {
  font-size: 13px;
}

.table_header_projects {
  background: #f2f4fa;
  margin-top: 2.3rem !important;
  margin-bottom: 1rem !important;
  font-size: 12px;
  padding-right: 10px !important;
  border: 1px solid #f2f4fa;
  border-radius: 5px;
}

.table_header_projects :global .column {
  padding-left: 0 !important;
  padding-right: 0;
}

.bms_transaction_list .table_header_projects h5 {
  margin-bottom: 0;
  margin-right: 10px;
  margin-left: 14px;
  font-size: 12px;
}
/****** end *******/

.bms_transaction_list :global .ui.input.right.icon {
  height: 24px;
}

.bms_transaction_list :global .ui.calendar {
  max-width: 115px;
  height: 24px;
  float: right;
  margin-right: 10px;
}

.bms_transaction_list :global .positive {
  font-weight: bold;
  color: #3cb776;
  padding: 0 5px;
}

.bms_transaction_list :global .dropdown {
  min-height: auto !important;
}

.bms_gutter_reset {
  padding-right: 0px !important;
  height: 100vh;
}

.vertical_list_height :global .menu {
  max-height: 65vh !important;
}

.sec_header {
  position: absolute;
  left: 4rem;
  top: 1rem;
  display: flex;
  font-size: 12px;
}
.checkbox_column {
  width: 4% !important;
  padding-right: 0px !important;
}
.button_reset {
  margin: 0px !important;
  max-height: 24px;
  min-width: 100px;
  width: 8%;
  font-size: 12px !important;
  padding: 6px;
}

.dayPickers {
  font-size: 12px !important;
}

.dayPickers input {
  width: 8rem !important;
}

.dayPickers :global .inline {
  margin-bottom: 0 !important;
}
.button_day_picker {
  width: 50%;
  font-size: 12px !important;
}

.selectableDayPicker {
  font-size: 11px !important;
}

.selectableDayPicker
  :global
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.selectableDayPicker :global .DayPicker-Day {
  border-radius: 0 !important;
}

.selectableDayPicker :global .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.selectableDayPicker :global .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.transaction_search {
  max-height: 24px;
  width: 15%;
  min-width: 100px;
  font-size: 12px;
  margin: 0 10px;
}

.positive {
  font-weight: bold;
  color: #3cb776;
}

.negative {
  font-weight: bold;
  color: #d9534f;
}

.bms_transaction_list :global .table_header_projects {
  margin-top: 2.3rem !important;
}

.dropdown_trigger {
  display: flex;
  justify-content: space-between;
  width: 100%;
  word-wrap: break-word;
}

.dropdown_trigger.first h5 {
  margin-left: 3em !important;
}

.purpose_booking {
  word-break: break-all;
}
.transactions_allaccounts {
  min-width: 115px !important;
  width: 15% !important;
  height: auto !important;
  margin-right: 9px;
  font-size: 12px;
  padding-top: 5px !important;
  padding-bottom: 1px !important;
  line-height: 1em;
}

.header_for_date {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.date_column_padding {
  padding-right: 5px !important;
}

@media (min-width: 1024px) {
  .bms_transaction_list :global .table_header_projects {
    margin-top: 0.75rem !important;
  }

  .selectableDayPicker {
    font-size: 12px !important;
  }
  .dayPickers :global .ui.attached.buttons {
    width: 16rem !important;
  }

  .selectableDayPicker :global .DayPicker-Month {
    margin-left: 1.2rem !important;
  }

  .items_list :global .ui.vertical.menu {
    max-height: calc(100vh - 18rem) !important;
  }
  .transactions_allaccounts :global .item {
    font-size: 10px !important;
  }
}

@media (min-width: 1240px) {
  .dropdown_trigger.first h5 {
    margin-left: 5em !important;
  }
  .transactions_allaccounts :global .item {
    font-size: 9px !important;
  }
}

@media (min-width: 1281px) {
  .transaction_search {
    width: 20%;
  }
  .button_reset {
    min-width: 100px;
    width: 10%;
  }
  .sec_header {
    position: initial;
  }
  .dropdown_trigger h5 {
    font-size: 13px;
  }
  .dayPickers {
    font-size: 12px !important;
  }

  .dayPickers :global .ui.attached.buttons {
    width: 16rem !important;
  }

  .selectableDayPicker {
    font-size: 12px !important;
  }

  .selectableDayPicker :global .DayPicker-Month {
    margin-left: 1.2rem !important;
  }

  .bms_transaction_list :global .table_header_projects {
    margin-top: 0.75rem !important;
  }

  .vertical_list_height :global .menu {
    max-height: 78vh !important;
  }

  .transactions_allaccounts {
    width: 20% !important;
    height: auto !important;
    margin-right: 9px;
    font-size: 12px;
    padding-top: 5px !important;
    padding-bottom: 1px !important;
    line-height: 1em;
  }

  .transactions_allaccounts :global .item {
    font-size: 12px !important;
  }

  .table_header_projects {
    margin-top: 0.75rem !important;
  }
  .items_list :global .ui.vertical.menu {
    max-height: calc(100vh - 16rem) !important;
  }
  .bms_transaction_list .table_header_projects h5 {
    font-size: 1rem;
  }
  .header_for_date {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }
  .date_column_padding {
    padding-right: inherit !important;
  }
  .dropdown_trigger.first h5 {
    margin-left: 5em !important;
  }
}
@media (min-width: 1500px) {
  .dropdown_trigger h5 {
    font-size: 14px;
  }
  .dropdown_trigger.first h5 {
    margin-left: 4em !important;
  }
}
