.header_above_list {
  width: 100%;
  background: #f2f4fa;
  height: 44px;
  padding: 10px !important;
}
.header_above_list_edit {
  width: 100%;
  background: #2185d0;
  height: 44px;
  padding: 10px !important;
  margin-bottom: 5px !important;
}
.details_heder_icon {
  top: 50% !important;
  float: right !important;
}
.details_heder_icon:hover {
  cursor: pointer !important;
  color: rgb(35, 35, 37) !important;
}
.heder_text {
  color: white !important;
  background: none !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 0.5 !important;
}
.heder_text_details {
  background: none !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 0.5 !important;
}
