.bms_sidebar {
  background-color: #f2f4fa !important;
  padding-top: 1rem;
  width: 260px !important;
  border: none !important;
  max-height: calc(100vh - 5rem) !important;
}
.bms_sidebar :global .header {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 16px !important;
}
.bms_sidebar :global .ui.vertical.menu {
  margin-bottom: 5px;
  width: auto;
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bms_sidebar :global .item {
  font-size: 15px !important;
  color: #575d62 !important;
}
.bms_sidebar :global a.item:hover {
  margin-right: 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.03) !important;
}
.bms_sidebar :global hr {
  border: 0;
}
.bms_sidebar :global .active.item {
  background: #fff !important;
  font-weight: 900 !important;
  -webkit-box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19);
  box-shadow: 0px 5px 16px rgba(52, 103, 191, 0.19) !important;
}
.bms_sidebar :global .active.item:hover {
  background: #fff !important;
}
.bms_sidebar :global .item > i.icon {
  width: 1.18em;
  float: left !important;
  margin: -3px 1em 0em 0em !important;
  font-size: 1.3em;
  color: #8b8f9d;
}
.bms_sidebar :global .active.item > i.icon {
  color: #2185d0;
}
.bms_button {
  margin: 0 20px 15px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  min-height: 52px !important;
  word-spacing: 10px !important;
}
.bms_button i {
  font-family: Icons;
  display: block;
  width: 16px;
  height: 16px;
  float: left;
  margin-right: 20px;
}

.active_menu_item {
  margin-top: 0 !important;
}

@media (min-width: 1281px) {
  .bms_sidebar {
    visibility: visible !important;
    width: 260px !important;
    transform: translate3d(0, 0, 0) !important;
    padding-top: 3.3rem;
  }
}
