.formAddButton {
  padding-left: 30% !important;
  margin: auto;
  float: right;
  order: 3 !important;
}
.formAddButton :hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
}
.segment_group {
  box-shadow: none !important;
}
.template_list {
  max-height: calc(100vh - 16em) !important;
  overflow: auto !important;
}
.title {
  display: grid;
  grid-template-columns: 92% 8%;
  background-color: #6fb5eb !important;
  color: white !important;
  font-size: 0.97em !important;
  padding: 0.75em 1em !important;
}
.message_block {
  font-size: 0.8em !important;
}
.parent_input {
  width: 100% !important;
}
.remove_btn_icon:hover {
  cursor: pointer !important;
}
.divider_actions {
  margin-bottom: 0px !important;
}
.div_actions {
  float: right !important;
  padding-bottom: 10px !important;
}
.item_in_list > a:hover {
  cursor: default !important;
  background-color: white !important;
}
.bottom_padd {
  padding-bottom: 0 !important;
}
.temp_in {
  width: 100% !important;
}
.temp_in {
  opacity: 0.9 !important;
}

.temp_in div {
  opacity: 0.9 !important;
}

.temp_in label,
.label_input {
  opacity: 0.96 !important;
  font-size: 0.92em !important;
}
.temp_in_icon:hover {
  cursor: pointer !important;
  color: #6fb5eb !important;
}
@media (min-width: 1281px) {
  .message_block {
    font-size: 0.9em !important;
  }
}
@media (min-width: 1600px) {
  .message_block {
    font-size: 1em !important;
  }
}
