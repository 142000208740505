.bms_segment {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  height: 100vh;
}

.bms_segment_zi {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  z-index: 1000 !important;
}

.bms_fixed {
  display: block !important;
  position: fixed !important;
  top: 5.9rem;
  left: 0px;
  width: 35px;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s width ease, 0.5s transform ease !important;
  z-index: 100 !important;
  padding: 0.7rem !important;
}

.bms_fixed i {
  margin-right: 1.3rem !important;
}

.bms_fixed span {
  font-size: 13px;
}

.bms_transaction_content :global .header {
  margin-top: 0 !important;
}

.bms_transactions_div {
  clear: both;
  display: block;
}
.bms_zindex {
  z-index: 0 !important;
}
.colFixedHeight {
  min-height: 56px;
}

@media (min-width: 1281px) {
  .bms_transaction_content {
    margin-left: 260px;
  }
  .bms_fixed {
    visibility: hidden;
  }

  .bms_zi {
    z-index: 1002 !important;
  }
}
