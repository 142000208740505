.accordition_container {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #dde1ee;
  border-radius: 5px !important;
  width: 100% !important;
  margin: 0px !important;
  font-size: 14px !important;
  margin-bottom: 10px !important;
}
.accordition_container :global .title,
.accordition_container :global .active.title {
  background: #f2f4fa !important;
  color: #575d62 !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  display: grid;
  grid-template-columns: 5% 90% 5%;
  word-break: break-word;
  padding: 0.8em 0px 0.8em !important;
}
.dropdown_icon {
  margin: auto !important;
}
.ac_content {
  padding: 10px !important;
}
.ac_content > div {
  font-size: 0.9em;
}
