.project_details_container {
  height: calc(100vh - 220px) !important;
  overflow: auto;
  margin-top: 14px;
}
.project_details_header {
  padding: 15px 25px;
  border: 1px solid #f2f4fa !important;
  border-radius: 5px !important;
  background: #f2f4fa !important;
  color: #575d62 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  margin-top: 3.2rem !important;
  text-align: left;
  font-size: 13px;
  font-weight: 700;
}
.contacts_details {
  font-size: 14px !important;
  margin: 0rem 4px !important;
  width: 97% !important;
}
.project_details_header_icon {
  line-height: 1;
  vertical-align: middle;
  font-size: 1.2em !important;
  float: right;
  cursor: pointer;
}
.project_details_header_icon:hover {
  color: #2185d0;
}
.delete_icon:hover {
  color: rgb(219, 65, 65);
}
.officeAddress {
  margin-bottom: 10px;
}
.details_border {
  border-bottom: 1px solid #dde1ee;
  width: 100% !important;
}
.details_border :last-child {
  text-align: right;
}
.progress_bar_margin {
  margin: 0 !important;
}
.accourdion_title_first {
  margin-top: 3rem;
  padding-left: 10px;
  text-transform: uppercase;
}
.resources_details {
  padding-bottom: 1em !important;
}
.accourdion_title {
  margin-top: 2rem;
  padding-left: 10px;
  text-transform: uppercase;
}
.accourdion_title_template {
  font-size: 14px;
  background: rgb(242, 244, 250);
  color: rgb(87, 93, 98);
  padding: 14px;
}
.project_details_template_segment {
  font-size: 14px;
  background: rgb(242, 244, 250);
  color: rgb(87, 93, 98);
}
.projectDetailsTemplate {
  margin: 1em 4px !important;
  width: 97% !important;
}
.projectDetailsTemplate :global .list :global .content {
  padding: 0.5em 0.8em !important;
}
.projectDetailsTemplate :global .content.active {
  padding: 0.8em 0.5em !important;
}
.title {
  background: #f2f4fa !important;
  font-size: 14px;
  color: rgb(87, 93, 98);
  margin-bottom: 10px;
  padding: 14px;
}
.preview_table_font {
  font-size: 12px !important;
}

.preview_table_font .icon_center {
  text-align: center !important;
  cursor: pointer;
}
.edit_link_docs_icon {
  cursor: pointer;
}

.sitemap_cell {
  text-align: center !important;
}

.preview_table_container {
  overflow: auto;
  padding: 15px 5px 10px 5px !important;
}
.project_description {
  white-space: pre-line;
  word-wrap: break-word;
}
.wrap_column {
  word-wrap: break-word;
}
/* Start Notes */
.note_details {
  border-radius: 5px;
  width: 98%;
  margin-top: 20px;
}
.note_details :global div.item {
  padding: 15px 0px 2px 0px !important;
}
.header_notes {
  background-color: #f2f4fa;
  justify-content: center;
  padding: 0.75em 1em;
  border-radius: 5px;
}
.header_title {
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-weight: bold !important;
  font-size: 14px !important;
  color: #575d62 !important;
}
.header_icon {
  float: right;
}
.header_icon:hover {
  color: #2185d0;
  cursor: pointer;
}
.description_of_issue {
  word-wrap: break-word;
  padding-left: 1em;
  padding-right: 1em;
}
/* End Notes */

.wrap_text {
  word-wrap: break-word;
}

@media (min-width: 1281px) {
  .project_details_header {
    font-size: 17px;
  }
  .preview_table_font {
    font-size: 13px;
  }
}
