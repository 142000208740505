.form_container {
  height: calc(100vh - 15.6em) !important;
  overflow-y: scroll;
  padding: 10px;
  padding-left: 0;
}
.required_label {
  color: #575d62 !important;
  font-size: 14px !important;
}
.divider_above_save {
  margin-top: 4px !important;
}
