h5 {
  font-weight: 800;
}
body ::-webkit-scrollbar {
  width: 4px !important;
}
.table_headerTemplateList {
  background: #f2f4fa;
  margin-top: 0.55rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  font-size: 12px;
  padding-right: 10px !important;
  border: 1px solid #f2f4fa;
  border-radius: 5px;
}
.table_headerTemplateList :global .row .column {
  padding-top: 0.22619048em;
  padding-bottom: 0.22619048em;
}
.items_list {
  margin-top: 0;
  margin-bottom: 0;
  width: 100% !important;
  padding: 1rem 0 1rem 1rem !important;
}

.items_list :global .ui.vertical.menu {
  max-height: calc(100vh - 15.5rem);
  margin-bottom: 5px !important;

  overflow: auto;
  width: 100%;
  border: 1px solid #dde1ee !important;
  border-radius: 0.28571429rem;
}

.header {
  margin-left: 2.8rem;
}
.dropdown_trigger {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.dropdown_trigger :global h5 {
  margin: 0px !important;
}
.buttons {
  font-size: 12px !important;
}
.all_checkbox {
  font-size: 12px !important;
  padding-right: 20px !important;
}
.middle_panel_form {
  font-size: 12px !important;
  margin-top: 2.2rem !important;
}

@media (min-width: 1281px) {
  .header {
    margin-left: 0;
  }
}
