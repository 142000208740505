.no_margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.required_label {
  color: #575d62 !important;
  font-size: 14px !important;
}
.custom_css_for_button {
  padding: 0px !important;
}
.custom_button {
  font-size: 0.8rem !important;
  background: red !important;
}
.list_of_roles {
  margin-top: 10px !important;
}
.list_of_roles :global a {
  padding: 10px 0px !important;
  font-weight: 400 !important;
}
