.bms_sidebar {
  background-color: #f2f4fa !important;
  padding-top: 4rem;
  width: 260px !important;
  border: none !important;
  max-height: calc(100vh - 5rem) !important;
}
.bms_button {
  margin: 0 20px 15px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  min-height: 52px !important;
  word-spacing: 10px !important;
  padding-left: 3rem !important;
  line-height: 1.15 !important;
}

.bms_button i {
  font-family: Icons;
  position: relative;
  top: -8px;
  right: 118px;
}

@media (min-width: 1281px) {
  .bms_sidebar {
    visibility: visible !important;
    width: 260px !important;
    transform: translate3d(0, 0, 0) !important;
    padding-top: 3.6rem;
  }
}
