body ::-webkit-scrollbar {
  width: 4px !important;
}
.main_class {
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
}
.container_exported {
  margin-top: 0;
  margin-bottom: 0;
  max-height: calc(100vh - 220px);
}
.my_grid {
  width: 100% !important;
  height: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 0em;
}
.bms_segment {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  height: 100vh !important;
}

.bms_segment_zi {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  z-index: 1002 !important;
}
.bms_partners_content {
  max-height: calc(100vh - 220px);
  margin-left: 0;
}
.bms_partners_content :global .ui.vertical.menu {
  max-height: calc(100vh - 170px);
  height: 83vh;
  overflow: auto;
  width: 100%;
  border: 1px solid #dde1ee;
  border-radius: 0.28571429rem;
}
.bms_zi {
  z-index: 0 !important;
}
.bms_fixed {
  display: block !important;
  position: fixed !important;
  top: 5.9rem;
  left: 0px;
  width: 35px;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s width ease, 0.5s transform ease !important;
  z-index: 100 !important;
  padding: 0.7rem !important;
}
.bms_fixed i {
  margin-right: 1.3rem !important;
}

.bms_fixed span {
  font-size: 13px;
}

.bms_zindex {
  z-index: 0 !important;
}
.pusher {
  max-height: calc(100vh - 220px) !important;
}

@media (min-width: 1281px) {
  .pusher {
    max-height: calc(100vh - 220px) !important;
  }
  .bms_partners_content {
    margin-left: 260px;
  }

  .bms_fixed {
    visibility: hidden;
  }

  .bms_zi {
    z-index: 1002 !important;
  }
  .main_class {
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
  }
}
