.projectDetailsContacts {
  margin: 0 auto !important;
  margin-top: 4px !important;
  margin-bottom: 0.7rem !important;
  width: 100% !important;
}
.projectDetailsContacts :global .item {
  display: flex !important;
}
.title {
  background: #f2f4fa !important;
  font-size: 16px;
  color: rgb(87, 93, 98);
  margin-bottom: 10px;
  align-items: center;
  padding: 0.55em 1em !important;
}
.title > i {
  top: 50% !important;
}
.title > span {
  display: inline-block;
  text-overflow: ellipsis !important;
  max-width: 80%;
  white-space: nowrap !important;
  overflow: hidden !important;
}
.minus_icon {
  float: right !important;
  padding-right: 1.5em;
}
.acordian_content {
  margin-bottom: 10px;
}
