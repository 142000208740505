.headerDocument {
  margin-left: 1rem !important;
  margin-top: 1rem !important;
  padding-bottom: 1rem !important;
  border-bottom: 1px solid rgb(158, 158, 158) !important;
}
.document_display_column {
  padding-right: 0 !important;
}
.document_display_column :nth-child(2) {
  margin-left: 10px !important;
}
.headerCreateNew {
  padding-bottom: 1rem !important;
  border-bottom: 1px solid rgb(158, 158, 158) !important;
  margin-top: 1rem !important;
  margin-right: 5px !important;
}
.companyFormHeight {
  margin-right: 5px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.companyFormHeight :global form {
  height: 47em !important;
  overflow: auto !important;
}
.button_height {
  line-height: 1.15 !important;
}
.button_action {
  background: #3cb776 !important;
  color: #fff !important;
}
.button_action_cancel {
  background: #d9534f !important;
  color: #fff !important;
}

@media (min-width: 1600px) {
  .companyFormHeight :global form {
    height: 39em !important;
    overflow: auto !important;
  }
}
