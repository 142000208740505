.roles_segment {
  font-size: 16px;
  float: left;
}
.plus_icon {
  float: right;
}
.plus_icon:hover {
  cursor: pointer;
}
.role_list_of_items {
  margin-bottom: 30px;
}
