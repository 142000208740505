.loginForm_header {
  margin-top: 1rem !important;
}
.another_account_button {
  margin-top: 10px !important;
}
.divider_margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.row_padding_bottom {
  padding-bottom: 0 !important;
}
.password_input {
  width: 100% !important;
}
.change_lang_button {
  width: 100% !important;
}
