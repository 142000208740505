.details_container {
  margin-top: 20px !important;
}
.item_line {
  margin-top: 15px !important;
}
.item_divider {
  margin: 0 !important;
}
.title {
  font-weight: 500 !important;
  display: grid;
  grid-template-columns: 92% 8%;
  background-color: #f2f4fa !important;
  border: 0 !important;
  padding: 0.75em 1em !important;
}
.edit_icon:hover {
  cursor: pointer !important;
}
