.bms_segment {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  height: 100vh;
}

.bms_segment_zi {
  margin-top: 0 !important;
  border: 0px !important;
  padding: 0px;
  z-index: 1000 !important;
}

.bms_accounts_content {
  margin-left: 0;
}

.accounts :global .message {
  bottom: 50px;
}

.bms_zi {
  z-index: 0 !important;
}
.bms_fixed {
  display: block !important;
  position: fixed !important;
  top: 5.9rem;
  left: 0px;
  width: 35px;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s width ease, 0.5s transform ease !important;
  z-index: 100 !important;
  padding: 0.7rem !important;
}

.bms_fixed i {
  margin-right: 1.3rem !important;
}

.bms_fixed span {
  font-size: 13px;
}

.bms_zindex {
  z-index: 0 !important;
}

.bms_column_height {
  height: 100vh !important;
}

.bms_link {
  text-decoration: underline;
}

.accounts_toaster :global .ui.info.message {
  bottom: 6rem !important;
}

.accounts_toaster :global .ui.floating.icon.warning.message {
  bottom: 6rem !important;
}

@media (min-width: 1281px) {
  .bms_accounts_content {
    margin-left: 260px;
  }
  .bms_fixed {
    visibility: hidden;
  }

  .bms_zi {
    z-index: 1002 !important;
  }
}
