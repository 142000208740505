/*whole details field */
.address_details {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #dde1ee !important;
  margin-bottom: 20px !important;
  font-size: 12px;
}
.address_details :global .content.active {
  padding: 1.153em !important;
  padding-bottom: 0px !important;
  height: fit-content;
}
/*details_title*/
.address_header {
  background: #f2f4fa !important;
  color: #575d62 !important;
  font-size: 1em !important;
}
/*details fields*/
.address_list {
  width: 100%;
  max-height: unset;
  overflow: hidden;
  border: 0 !important;
  border-bottom: 1px solid #dde1ee;
  border-radius: 0;
  font-size: 1em !important;
  padding-bottom: 1.2em !important;
}
.address_line {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: grid !important;
  grid-template-columns: 25% 75%;
  word-wrap: break-word;
}
.divider_line {
  margin-bottom: 0.3em !important;
  margin-top: 1.2em !important;
}
.address_grid {
  padding: 0px !important;
  display: grid !important;
  grid-template-rows: 3;
}

.address_list :global .item .content {
  padding: 0 !important;
}

@media (min-width: 1281px) {
  .address_details {
    font-size: 13px;
  }
  .address_line {
    grid-template-columns: 30% 70%;
  }
}
@media (min-width: 1600px) {
  .address_details {
    font-size: 14px;
  }

  .address_line {
    grid-template-columns: 21% 79%;
  }
}
@media (min-width: 1024px) {
  .address_details :global .content.active {
    padding: 20px !important;
    padding-bottom: 0px !important;
  }
}
