.bms_header {
  background: #2185d0;
  padding: 15px 10px 15px 25px;
  margin: 10px 0 10px;
  text-align: left;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}

.invoice_thumbnails {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  max-height: calc(100vh - 295px);
  overflow: auto;
}

/* Documents/New Document/Link documents preview */
.invoice_thumbnails.single_invoice {
  max-height: calc(100vh - 15rem);
  display: block;
}

/* Documents/New Document/Link documents preview */
.invoice_thumbnails.single_invoice img {
  width: 100%;
  height: auto;
}

.documentImagePreviewContainer {
  max-width: 100% !important;
  max-height: 100% !important;
  overflow: hidden !important;
}

.selectedLinkedPreview {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
