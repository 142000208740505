.bms_documents_item {
  padding: 20px 0px 20px !important;
}
.document_payment_status_label {
  border: 1px solid #f3f5fa;
  border-image-source: linear-gradient(10deg, #f3f5fa, #b9cac4);
  border-image-slice: 1;
  text-align: center;
  padding: 3px !important;
  margin: 0 12px;
}
.document_payment_status_label :global .ui.menu {
  min-height: 0rem !important;
}
.paid {
  font-size: 9px !important;
  font-weight: 400;
  background-color: #32cd32 !important;
  width: 4.375rem;
  border-radius: 0.3125rem !important;
  opacity: 0.7;
}
.not_paid {
  background-color: #e47312 !important;
  font-size: 9px !important;
  font-weight: 400;
  width: 4.375rem;
  border-radius: 0.3125rem !important;
  opacity: 0.7;
}
.partial_paid {
  background-color: #a7c225e8 !important;
  font-size: 9px !important;
  font-weight: 400;
  width: 4.375rem;
  border-radius: 0.3125rem !important;
  opacity: 0.7;
}
.not_relevant {
  background-color: #b7b1e9 !important;
  font-size: 9px !important;
  font-weight: 400;
  width: 4.375rem;
  border-radius: 0.3125rem !important;
  opacity: 0.7;
}
.notsucc {
  background-color: #d55252 !important;
  font-size: 9px !important;
  font-weight: 400;
  width: 4.375rem;
  border-radius: 0.3125rem !important;
  opacity: 0.7;
}
.pending {
  background-color: #e5d8bf !important;
  font-size: 9px !important;
  font-weight: 400;
  width: 4.375rem;
  border-radius: 0.3125rem !important;
  opacity: 0.7;
}
.unresolved {
  background-color: #8d9df1 !important;
}
.approved {
  background-color: #b5d77e !important;
}
.verified {
  background-color: #77d1a1 !important;
}
.declined {
  background-color: #7ed7d3 !important;
}
.unverified {
  background-color: #8d9df1 !important;
}
.partner_name_column {
  word-wrap: break-word;
}
.colFixedHeight {
  height: 65px;
}
