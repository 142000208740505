.form_container {
  z-index: 1 !important;
  position: relative !important;
}
.table_width {
  width: 99% !important;
  margin-top: 1px !important;
  font-size: 0.8em !important;
}
.disabled_color {
  opacity: 1 !important;
}
.disabled_color_align {
  opacity: 1 !important;
}
.disabled_color_align input {
  opacity: 1 !important;
  color: #000000 !important;
  margin-top: 0 !important;
  text-align: right !important;
  font-size: 0.9em;
}
.total_header {
  position: relative;
  margin-top: 2em !important;
  margin-right: 10px !important;
}
.total_header i {
  position: absolute;
  right: 1.4rem;
  bottom: 5px;
  font-size: 20px !important;
}
.total_header i:hover {
  color: #d9534f;
  cursor: pointer;
}
.total_header_2 {
  margin-bottom: 0.5em !important;
}
.validation_message_red {
  font-size: 12px;
  color: #db2828;
  font-weight: bold;
}
.right_column {
  padding-right: 10px !important;
}
.padding_bottom_null {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.padding_bottom_ten {
  padding-bottom: 10 !important;
  padding-top: 0 !important;
}

.padding_bottom_ten h5 i,
.padding_bottom_null h5 i {
  font-size: 11px !important;
}
.padding_right_null {
  padding-right: 0 !important;
}
.divider_margin {
  margin: 5px 0 !important;
}
.subtotal_table {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  width: 99% !important;
  margin-top: 1px !important;
  font-size: 0.8em !important;
}

.subtotal_table tr th i {
  font-size: 11px;
}
.padding_top_bottom_null {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.datedown {
  background-color: #000000;
}
.small_input {
  width: 3.5em !important;
}
.middle_input {
  width: 5.2em !important;
}
.total_container {
  overflow-x: scroll;
}
.default_cell {
  padding: 5px 2px 5px 2px !important;
}
.clear_earlydate:hover {
  color: #db2828 !important;
}

@media (min-width: 1230px) {
  .total_container {
    overflow-x: inherit;
  }
}
@media (min-width: 1281px) {
  .small_input {
    width: 3.5rem !important;
  }
  .middle_input {
    width: 5.5rem !important;
  }
  .table_width {
    font-size: 1em !important;
  }
  .disabled_color_align input {
    font-size: 1em;
  }
  .subtotal_table {
    font-size: 1em !important;
  }
}
