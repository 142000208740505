.right_panel {
  width: 100% !important;
  padding: 12px !important;
  padding-right: 5px;
}

.header {
  background: #f2f4fa;
  padding: 15px 25px;
  margin-top: 2.5rem;
  border-radius: 5px;
  text-align: left;
  color: #575d62;
  font-size: 17px;
  font-weight: 700;
}

.company_details_container {
  height: calc(100vh - 205px);
  overflow: auto;
  margin-top: 25px;
  padding-right: 5px !important;
}

.delete_and_archive_icons {
  line-height: 1;
  vertical-align: middle;
  font-size: 1.2em !important;
  float: right !important;
  cursor: pointer !important;
}

.trash:hover {
  color: rgb(212, 101, 101);
}

.edit:hover {
  color: #2185d0;
}

.type_segment,
.role_segment {
  font-size: 16px !important;
  background: rgb(242, 244, 250) !important;
  color: rgb(87, 93, 98) !important;
}

.type_row {
  margin-left: 0px !important;
  padding-left: 6px !important;
}

.wrap_column {
  display: inline-block !important;
  padding-right: 0px !important;
  word-wrap: break-word;
}

/* Start Notes */
.note_details {
  border-radius: 5px;
  width: 100%;
}

.note_details :global div.item {
  padding: 15px 0px 2px 0px !important;
}

.header_notes {
  background-color: #f2f4fa;
  justify-content: center;
  padding: 0.75em 1em;
  border-radius: 5px;
}

.header_title {
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-weight: bold !important;
  font-size: 14px !important;
  color: #575d62 !important;
}

.header_icon {
  float: right;
}

.header_icon:hover {
  color: #2185d0;
  cursor: pointer;
}

.description_of_issue {
  word-wrap: break-word;
  padding-left: 1em;
  padding-right: 1em;
}
/* End Notes */
.full_width {
  width: 100% !important;
}
.project_details_header_icon {
  line-height: 1;
  vertical-align: middle;
  font-size: 1.2em !important;
  float: right;
  cursor: pointer;
}
.project_details_header_icon:hover {
  color: #2185d0;
}
